import { useState, useEffect } from "react";
import UserInfo from "./UserInfo";
import { useAuthUserContext } from "../auth/AuthContext";
import InfoItem from "../common/list_item/InfoItem";
import Modal from "../common/Modal";
import Navigation from "../hud/Navigation";
import { useBackend } from "../hooks/Backend";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EnvelopeIcon,
  KeyIcon,
  DevicePhoneMobileIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import Loader from "../common/loader/Loader";

function UserSetting() {
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const auth = useAuthUserContext();
  const [showMailConfirmModal, setShowMailConfirmModal] = useState(false);

  const [showPwChangeModal, setShowPwChangeModal] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const api = useBackend();

  useEffect(() => {
    api.setting_my_info().then(({ data }) => {
      setUser(data);
      setLoading(false);
    });

    if (searchParams.get("sendemailresetpassword") != null) {
      setEmail(searchParams.get("sendemailresetpassword"));
      setShowPwChangeModal(true);
    } else if (searchParams.get("sendemailreset") != null) {
      setEmail(searchParams.get("sendemailreset"));
      setShowMailConfirmModal(true);
    }
  }, []);

  const handleClosePwChangePopup = () => {
    searchParams.delete("query");
    setShowPwChangeModal(false);
    clearSearchParam("sendemailresetpassword");
  };

  const handleCloseMailChangePopup = () => {
    searchParams.delete("query");
    setShowMailConfirmModal(false);
    clearSearchParam("sendemailreset");
  };

  const clearSearchParam = (param) => {
    searchParams.delete(param);
    navigate({
      search: searchParams.toString(),
      replace: true,
    });
  };

  return (
    <>
      {loading && <Loader />}
      <Navigation />

      {user && <UserInfo isEdit={true} data={user} />}

      <div className="grid grid-cols-1 user-info-container">
        <InfoItem
          title={user?.email}
          // onClick={() => setShowMailConfirmModal(true)}
          //onClick={() => navigate("/email-reset")}
          icon={<EnvelopeIcon className="h-6 w-6" />}
        />
        <InfoItem
          title="*********"
          onClick={() => navigate("/password-reset")}
          icon={<KeyIcon className="h-6 w-6" />}
        />
        <InfoItem
          title={`検索時：${user?.allow_send_position_on_search ? "OK" : "NG"}`}
          onClick={() => navigate("/location-setting")}
          icon={<MapPinIcon className="h-6 w-6" />}
        />
        <InfoItem
          title={`許可済: ${user?.allow_use_gyro ? "OK" : "NG"}`}
          onClick={() => navigate("/sensor-setting")}
          icon={<DevicePhoneMobileIcon className="h-6 w-6" />}
        />
        <Modal
          open={showPwChangeModal}
          onClose={() => handleClosePwChangePopup(false)}
          title="メールを送信しました。"
          text={`${email ? email : ""} を確認してください。`}
        />

        <Modal
          open={showMailConfirmModal}
          onClose={() => handleCloseMailChangePopup()}
          title="パスワードを更新しました。"
        />
      </div>
      <div className="text-center">
        <button className="btn btn-danger" onClick={() => auth.logged_out()}>
          ログアウト
        </button>
      </div>
    </>
  );
}
export default UserSetting;
export const userSettingURL = "/user/setting";
