
import { React, memo, useState, useCallback } from "react";
import axios from "axios";
import logo from './Landing_Logo.png'
import {Link} from 'react-router-dom'
import { regisrationUrl } from "../auth/Registration";
import { loginURL } from "../auth/Login";

export default function Landing() {
return (
  <div>
    <div className="text-3xl font-black flex justify-center mt-32"> Petaへようこそ </div>
    <img src={logo} className="w-full px-32 pt-12 pb-16" />
    <div className="text-xl font-black flex justify-center mt-0"> 街中に広告が浮かび上がる？ </div>
    <div className="text-xl font-black flex justify-center mt-2 mb-12">新感覚VRサービス</div>
    <Link to={regisrationUrl} className="bg-[#D39A46] text-xl text-white font-bold flex justify-center rounded-full mx-12 my-4 p-3 ">アカウントを作成する</Link>
    <Link to={loginURL} className="border-solid border-1 border-sky-600 text-xl font-bold flex justify-center rounded-full mx-12 my-4 p-3 ">ログインする</Link>
  </div>
);
}
export const landingURL = "/"