import React, { useState, useEffect } from "react";
import { useAxes } from "../hooks/Axes";
import { useBackend } from "../hooks/Backend";

function ToyDetailMock(props) {
  const { data, handleOpenOverlay, farness } = props;

  const [v, e] = useAxes();
  const api = useBackend();
  const rotation = data?.appear?.rotation;
  const position = data?.appear?.position;

  return (
    <>
      <div
        className="toy-mock"
        style={{ width: 100 - farness }}
        onClick={() => handleOpenOverlay(data)}
      >
        <img src={data?.image} alt="Logo" />
      </div>
    </>
  );
}
export default ToyDetailMock;
