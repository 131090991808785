import { React } from "react";
import Navigation from "./Navigation";
import { useAuthUserContext } from "../auth/AuthContext";
import { HeartIcon } from "@heroicons/react/24/outline";

function HUD(props) {
  const auth = useAuthUserContext();
  return (
    <>
      {/* <Header /> */}
      {/* <div className="home p-5 flex-none">
        <PostForm />
      </div> */}
      <div className="hub-action">
        <div className="action-item">
          <img src={props.event?.eyecatch_image} alt="Logo" />
          <span>{props.event?.catchphrase}</span>
          {/* <button>
            <HeartIcon className="h-6 w-6" />
          </button> */}
        </div>
      </div>
      <Navigation />
    </>
  );
}

export default HUD;
