import React, { useState, useEffect } from "react";
import { useBackend } from "../../hooks/Backend";
import { useAuthUserContext } from "../../auth/AuthContext";
import PostItem from "../../common/list_item/PostItem";
import StageSettingItem from "./StageSettingItem";
import Navigation from "../../hud/Navigation";
import SideBar from "../../common/sidebar/SideBar";

function StageSetting() {
  const [posts, setPosts] = useState([]);
  // const [toys, setToys] = useState([]);
  const api = useBackend();
  const auth = useAuthUserContext();
  const user = auth?.user;
  const data = {
    searched_stage: [
      {
        id: "3a86bd18-057d-4b4f-b4bb-493a9d5582f5",
        catchphrase: "ここに来るまで、推しは一人でいいと思ってた。",
        name: "レジェンドガチャ",
        eyecatch_image:
          "https://development.project-peta.com/images/stage/3a86bd18-057d-4b4f-b4bb-493a9d5582f5.jpg",
        place: {
          name: "東京都新宿区大久保２丁目６−１０ 新宿第二アルプスマンション B1",
          latitude: 35.70202094650426,
          longitude: 139.70784550067964,
          r: 2000,
        },
        instruction:
          "推しってそうそう出会えるものじゃない。ここに来るまでは、そう思ってた。「自分の心って、こんなカンタンに動くの？」そう思えるほどの“原石”が、ここに集まる。",
        toys: [
          {
            id: "af3af06d-7cc1-45c4-b8fd-1eca4b4daccb",
            name: "手に入るのは石か、美男美女か。",
            image:
              "https://development.project-peta.com/images/toy/af3af06d-7cc1-45c4-b8fd-1eca4b4daccb.png",
            display_type: "3d",
            appear: {
              size: {
                width: 1280,
                height: 720,
              },
              rotation: {
                yaw: 0,
                pitch: 0,
                roll: 0,
              },
              position: {
                farness: 10,
                height: 10,
                how_far_right: 10,
              },
              place: {
                latitude: 35.70202094650426,
                longitude: 139.70784550067964,
                r: 200000000,
              },
              term: {
                start: "1970-01-01T00:00:00",
                end: "2037-12-31T23:59:59",
              },
              show: true,
            },
            rank: null,
          },
        ],
      },
      {
        id: "daf20bdc-9159-4459-80d4-01a9c999fc48",
        catchphrase: "それぞれのスタイル、それぞれの美。",
        eyecatch_image:
          "https://development.project-peta.com/images/stage/daf20bdc-9159-4459-80d4-01a9c999fc48.jpg",
        place: {
          name: "東京都新宿区大久保２丁目６−１０ 新宿第二アルプスマンション B1",
          latitude: 35.70202094650426,
          longitude: 139.70784550067964,
          r: 2000,
        },
        toys: [
          {
            id: "b0f2fe24-1a67-44c2-bc36-939bdb09f0eb",
            name: "どんな美女を写真に収めた？",
            image:
              "https://development.project-peta.com/images/toy/b0f2fe24-1a67-44c2-bc36-939bdb09f0eb.svg",
            display_type: "3d",
            appear: {
              size: {
                width: 1280,
                height: 720,
              },
              rotation: {
                yaw: 0,
                pitch: 0,
                roll: 0,
              },
              position: {
                farness: 60,
                height: 20,
                how_far_right: 0,
              },
              place: {
                latitude: 35.70202094650426,
                longitude: 139.70784550067964,
                r: 200000000,
              },
              term: {
                start: "1970-01-01T00:00:00",
                end: "2037-12-31T23:59:59",
              },
              show: true,
            },
            rank: null,
          },
        ],
      },
      {
        id: "06eb8d25-8ebd-440c-81ec-229f9f2369fc",
        catchphrase: "伝説の女優たちを、あなたの手に。",
        eyecatch_image:
          "https://development.project-peta.com/images/stage/06eb8d25-8ebd-440c-81ec-229f9f2369fc.jpeg",
        place: {
          name: "東京都新宿区大久保２丁目６−１０ 新宿第二アルプスマンション B1",
          latitude: 35.70202094650426,
          longitude: 139.70784550067964,
          r: 2000,
        },
        toys: [
          {
            id: "03b76d1c-489b-441e-b472-80cce6f14f6f",
            name: "レジェンドをあなたの手に。",
            image:
              "https://development.project-peta.com/images/toy/03b76d1c-489b-441e-b472-80cce6f14f6f.png",
            display_type: "3d",
            appear: {
              size: {
                width: 1280,
                height: 720,
              },
              rotation: {
                yaw: 0,
                pitch: 0,
                roll: 0,
              },
              position: {
                farness: 60,
                height: 20,
                how_far_right: 0,
              },
              place: {
                latitude: 35.70202094650426,
                longitude: 139.70784550067964,
                r: 200000000,
              },
              term: {
                start: "1970-01-01T00:00:00",
                end: "2037-12-31T23:59:59",
              },
              show: true,
            },
            rank: null,
          },
        ],
      },
      {
        id: "34da8dd1-b378-4cd0-b502-f3eaca881203",
        catchphrase: "おすすめの漫画集めたんで、引いたやつ読んでみて下さい。",
        eyecatch_image:
          "https://development.project-peta.com/images/stage/34da8dd1-b378-4cd0-b502-f3eaca881203.jpg",
        place: {
          name: "東京都新宿区大久保２丁目６−１０ 新宿第二アルプスマンション B1",
          latitude: 35.70202094650426,
          longitude: 139.70784550067964,
          r: 2000,
        },
        toys: [
          {
            id: "d725a581-af6c-48fa-a1d7-e28f4f709560",
            name: "TAPしておすすめの漫画を手に入れよう",
            image:
              "https://development.project-peta.com/images/toy/d725a581-af6c-48fa-a1d7-e28f4f709560.webp",
            display_type: "3d",
            appear: {
              size: {
                width: 1280,
                height: 720,
              },
              rotation: {
                yaw: 0,
                pitch: 0,
                roll: 0,
              },
              position: {
                farness: 50,
                height: 0,
                how_far_right: 0,
              },
              place: {
                latitude: 35.70202094650426,
                longitude: 139.70784550067964,
                r: 200000000,
              },
              term: {
                start: "1970-01-01T00:00:00",
                end: "2037-12-31T23:59:59",
              },
              show: true,
            },
            rank: null,
          },
        ],
      },
      {
        id: "924c7f2a-affe-4754-9d36-d743cbb1f4ea",
        catchphrase: "あなたは肉を焼くことができるか。",
        eyecatch_image:
          "https://development.project-peta.com/images/stage/924c7f2a-affe-4754-9d36-d743cbb1f4ea.jpg",
        place: {
          name: "東京都新宿区大久保２丁目６−１０ 新宿第二アルプスマンション B1",
          latitude: 35.70202094650426,
          longitude: 139.70784550067964,
          r: 2000,
        },
        toys: [
          {
            id: "d725a581-af6c-48fa-a1d7-e28f4f709560",
            name: "TAPしておすすめの漫画を手に入れよう",
            image:
              "https://development.project-peta.com/images/toy/d725a581-af6c-48fa-a1d7-e28f4f709560.webp",
            display_type: "3d",
            appear: {
              size: {
                width: 1280,
                height: 720,
              },
              rotation: {
                yaw: 0,
                pitch: 0,
                roll: 0,
              },
              position: {
                farness: 50,
                height: 0,
                how_far_right: 0,
              },
              place: {
                latitude: 35.70202094650426,
                longitude: 139.70784550067964,
                r: 200000000,
              },
              term: {
                start: "1970-01-01T00:00:00",
                end: "2037-12-31T23:59:59",
              },
              show: true,
            },
            rank: null,
          },
        ],
      },
      {
        id: "24e91471-a9b4-43aa-b337-9f8252d6d7d5",
        catchphrase: "あなたは寿司を食べることができるか。",
        eyecatch_image:
          "https://development.project-peta.com/images/stage/24e91471-a9b4-43aa-b337-9f8252d6d7d5.jpg",
        place: {
          name: "東京都新宿区大久保２丁目６−１０ 新宿第二アルプスマンション B1",
          latitude: 35.70202094650426,
          longitude: 139.70784550067964,
          r: 2000,
        },
        toys: [
          {
            id: "1a21e8f3-aedc-44f5-b56c-4c4bea738054",
            name: "TAPで寿司を取ろう！",
            image:
              "https://development.project-peta.com/images/toy/1a21e8f3-aedc-44f5-b56c-4c4bea738054.png",
            display_type: "3d",
            appear: {
              size: {
                width: 1280,
                height: 720,
              },
              rotation: {
                yaw: 0,
                pitch: 0,
                roll: 0,
              },
              position: {
                farness: 60,
                height: -10,
                how_far_right: 0,
              },
              place: {
                latitude: 35.70202094650426,
                longitude: 139.70784550067964,
                r: 200000000,
              },
              term: {
                start: "1970-01-01T00:00:00",
                end: "2037-12-31T23:59:59",
              },
              show: true,
            },
            rank: null,
          },
        ],
      },
    ],
  };

  useEffect(() => {
    setPosts(data.searched_stage);
  }, []);

  return (
    <>
      <div className="flex-1 px-12 stage-setting-page">
        <div className="timeline-header">
          <h3 className="text-lg text-w-800">ガチャ設定</h3>
          <div className="action">
            <div className="action-item">
              <div className="user-ava">
                <img src={user?.icon} alt="user ava" />
              </div>
            </div>
          </div>
        </div>

        <SideBar data={posts} />

        <div className="grid grid-cols-1 gap-4 stage-setting-content">
          <div className="col-span-1 gap-4">
            {posts != null &&
              posts.map((post) => <StageSettingItem data={post} />)}
          </div>
        </div>
      </div>
    </>
  );
}

export default StageSetting;
export const stageSettingURL = "/stage-setting";
