import { useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
export const usePosition = () => {
  const [now_i_am_in, setNowIAmIn] = useState({
    latitude: null,
    longitude: null,
    active: false,
    isWatching: false,
    watchId: null,
  });
  const startGetCurrentPosition = () => {
    const toastId = toast.loading('あたりを検索しています。');
    const watchId = navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setNowIAmIn({ latitude: latitude, longitude: longitude, active: true, isWatching: false, watchId: watchId  });
      toast.success("検索しました！", {id : toastId});
    });
  };
  const startGetCurrentPositionSilent = () => {
    const watchId = navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setNowIAmIn({ latitude: latitude, longitude: longitude, active: true, isWatching: false, watchId: watchId });
    });
  };
  const startWatchPosition = () => {
    const watchId = navigator.geolocation.watchPosition((position) => {
      const { latitude, longitude } = position.coords;
      setNowIAmIn({ latitude: latitude, longitude: longitude, active: true, isWatching: true, watchId: watchId });
    });
  };

  const stopWatchPosition = () => {
    navigator.geolocation.clearWatch(now_i_am_in.watchId);
    setNowIAmIn({ latitude: null, longitude: null, active: false, isWatching: false, watchId: null });
  };

  const distance = useCallback((lat1, lng1, lat2, lng2) => {
    lat1 = (lat1 * Math.PI) / 180;
    lng1 = (lng1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;
    lng2 = (lng2 * Math.PI) / 180;

    const A = 6378137.0;
    const B = 6356752.31414;
    const e2 = (Math.pow(A, 2) - Math.pow(B, 2)) / Math.pow(A, 2);

    const N1 = A / Math.sqrt(1 - e2 * Math.pow(Math.sin(lat1), 2));
    const N2 = A / Math.sqrt(1 - e2 * Math.pow(Math.sin(lat2), 2));
    const N = (N1 + N2) / 2;
    const dx =
      N2 * Math.cos(lat2) * Math.cos(lng2) -
      N1 * Math.cos(lat1) * Math.cos(lng1);
    const dy =
      N2 * Math.cos(lat2) * Math.sin(lng2) -
      N1 * Math.cos(lat1) * Math.sin(lng1);
    const dz = N2 * (1 - e2) * Math.sin(lat2) - N1 * (1 - e2) * Math.sin(lat1);
    const Rn = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2) + Math.pow(dz, 2));

    const F = Math.asin(Rn / 2 / N);

    return 2 * N * F;
  }, []);

  const get_in_from_here = useCallback((puts, radius) => {
    if (!puts) return false;
    if (!now_i_am_in) return false;
    if (!now_i_am_in.active) return false;
    const r = radius || 100;
    console.log(
      puts,
      now_i_am_in,
      distance(
        puts.latitude,
        puts.longitude,
        now_i_am_in.latitude,
        now_i_am_in.longitude
      )
    );
    return (
      distance(
        puts.latitude,
        puts.longitude,
        now_i_am_in.latitude,
        now_i_am_in.longitude
      ) <= r
    );
  }, [now_i_am_in]);
  useEffect(() => {
    startWatchPosition();
    return stopWatchPosition();
  }, []);
  return [now_i_am_in, startGetCurrentPosition, startGetCurrentPositionSilent, get_in_from_here];
};
