import React, { useState, useEffect } from "react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import axios from "axios";

function ImageUpload(props) {
  const {
    accept = ".jpg, .jpeg, .png",
    handleUpload,
    icon,
    defaultImage,
  } = props;

  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const getImageNameAndType = (name) => {
    if (name) {
      return name.split(".")[0] + "/" + name.split(".")[1];
    }
    return "";
  };

  const onChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        console.log(file);
        // console.log(reader.result);
        setFile(file);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (imagePreviewUrl) {
      //submit image

      handleUpload(file, imagePreviewUrl);
    }
  }, [imagePreviewUrl]);

  return (
    <div className="input-container h-100">
      <input
        type="file"
        accept={accept}
        onChange={onChange}
        className="d-none"
      />
      <img src={imagePreviewUrl ? imagePreviewUrl : defaultImage} />
      {icon ? icon : <PhotoIcon className="h-6 w-6 text-white" />}
    </div>
  );
}

export default ImageUpload;
