import React, { useState, useEffect } from "react";
import PrizeItem from "../../../common/list_item/PrizeItem";
import { PlusIcon } from "@heroicons/react/24/outline";
import StageSettingModal from "../StageSettingModal";

function RarityItem(props) {
  const { data, empty } = props;

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (data) {
      setImage(data.image);
    }
  }, [data]);

  const [showStageModal, setShowStageModal] = useState(false);

  return (
    <>
      {empty ? (
        <div className="btn-add" onClick={() => setShowStageModal(true)}>
          <PlusIcon className="h-6 w-6" />
        </div>
      ) : (
        <div className="post-item" onClick={() => setShowStageModal(true)}>
          <img src={image} alt="Logo" />
        </div>
      )}

      <StageSettingModal
        title={title}
        setTitle={setTitle}
        image={image}
        setImage={setImage}
        showStageModal={showStageModal}
        setShowStageModal={setShowStageModal}
      />
    </>
  );
}

export default RarityItem;
