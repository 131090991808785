import axios from "axios"
import { Navigate, useNavigate } from "react-router"
import { useLocation } from "react-router-dom";
import { useRedirect } from "../hooks/Redirect";
import { useBackend} from "../hooks/Backend";

export default function OAuthLoginHandler() {
    const navigate = useNavigate()
    const redirect = useRedirect()
    const api = useBackend() 
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const state = query.get('state')
    const code = query.get('code')
    const auth_login = async () => {
        await api.oauth_login_callback(code , state )
        .then(e => redirect.to_main_view())
        .catch((e) => {
            if (e.response.status == 400) {
              if (e.response.data.detail == "OAUTH_NOT_AVAILABLE_EMAIL")
                redirect.to_top();
              if (e.response.data.detail == "OAUTH_USER_ALREADY_EXISTS") 
              redirect.to_login();
              if (e.response.data.detail == "LOGIN_BAD_CREDENTIALS") 
                redirect.to_top()
            }
                redirect.to_top();
          });
    }
    auth_login()
    return (
        <>registering</>
    )
}
export const loginCallbackURL = "/login/callback"