import { useState, useEffect } from "react";
import InfoItem from "../common/list_item/InfoItem";
import PetaLogo from "../image/peta-logo.png";
import { useNavigate } from "react-router-dom";
import { useAuthUserContext } from "../auth/AuthContext";

function PointTab(props) {
  const { wallet } = props;
  const navigate = useNavigate();
  const auth = useAuthUserContext();

  const formattedAmount = (amount) => {
    if (amount) {
      return amount.toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });
    } else {
      return 0;
    }
  };

  return (
    <>
      <div className="wallet">
        <h3>{formattedAmount(wallet)}</h3>
        <p>Points in your wallet</p>
      </div>
      <div className="grid grid-cols-1 user-info-container point-container">
        <InfoItem
          title="100 Peta pt"
          btnText="チャージ"
          subTitle="¥ 100"
          onClick={() =>
            navigate("/checkout?item=" + "100 Peta pt" + "&amount=" + 100)
          }
          icon={<img src={PetaLogo} />}
        />
        <InfoItem
          title="300 Peta pt"
          btnText="チャージ"
          subTitle="¥ 300"
          onClick={() =>
            navigate("/checkout?item=" + "300 Peta pt" + "&amount=" + 300)
          }
          icon={<img src={PetaLogo} />}
        />
        <InfoItem
          title="500 Peta pt"
          btnText="チャージ"
          subTitle="¥ 500"
          onClick={() =>
            navigate("/checkout?item=" + "500 Peta pt" + "&amount=" + 500)
          }
          icon={<img src={PetaLogo} />}
        />
        <InfoItem
          title="1000 Peta pt"
          subTitle="¥ 1000"
          btnText="チャージ"
          onClick={() =>
            navigate("/checkout?item=" + "1000 Peta pt" + "&amount=" + 1000)
          }
          icon={<img src={PetaLogo} />}
        />
        <InfoItem
          title="3,000 Peta pt"
          subTitle="¥ 3,000"
          btnText="チャージ"
          onClick={() =>
            navigate("/checkout?item=" + "3000 Peta pt" + "&amount=" + 3000)
          }
          icon={<img src={PetaLogo} />}
        />
        <InfoItem
          title="30,000 Peta pt"
          subTitle="¥ 30,000"
          btnText="チャージ"
          onClick={() =>
            navigate("/checkout?item=" + "30000 Peta pt" + "&amount=" + 30000)
          }
          icon={<img src={PetaLogo} />}
        />
      </div>
    </>
  );
}
export default PointTab;
