import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImage } from "../../hooks/Image";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";

function PostItem(props) {
  const navigate = useNavigate();
  const { data, onClick, minial } = props;

  const { loading, error, image } = useImage(data?.eyecatch_image);
  const imageUrl = data?.eyecatch_image;

  const goToDetail = (id) => {
    var url = "/world-scanner";
    if (data != null && data.isTicket) {
      url = "/ticket-detail";
    }
    url += "?id=" + id;
    navigate(url);
  };
  return (
    <div
      className={`post-item ${data?.isTicket ? "ticket" : ""} ${
        minial ? "minimal" : ""
      }`}
      onClick={onClick ? onClick : () => goToDetail(data.id)}
    >
      <div class="image-layer"></div>
      <img src={imageUrl} alt="Logo" />
      {data?.eyecatch_bgr ? (
        <div className={`post-item-bgr ${data?.eyecatch_bgr}`}></div>
      ) : null}
      <div className="post-content">
        <div className="post-title">{data?.catchphrase}</div>
        {!data?.isTicket && data?.fromTime && data?.toTime ? (
          <div className="post-time">
            {data?.fromTime} ~ {data?.toTime}
          </div>
        ) : null}
      </div>
      <div className="post-footer">
        <div className="d-flex">
          {!minial && <div className="post-location">{data?.place?.name}</div>}

          <div className="post-action">
            {!data?.isTicket ? (
              <div className="post-action-item right">
                {!minial && <h2 className="text-sm text-w-800 ">見る</h2>}

                <ArrowLeftOnRectangleIcon
                  className="h-6 w-6 text-white"
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default PostItem;
