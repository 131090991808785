import { useState, useEffect } from "react";
import Tabs from "../common/tab/Tabs";
import Panel from "../common/tab/Panel";
import Score from "./NewScore";
import PointTab from "../point/PointTab";
import UserInfo from "./UserInfo";
import Navigation from "../hud/Navigation";
import { useBackend } from "../hooks/Backend";
import { useLocation } from "react-router-dom";
import Modal from "../common/Modal";
import Loader from "../common/loader/Loader";
import ScoreTab from "./score2/ScoreTab";

function User() {
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [user, setUser] = useState(null);
  const [paymentpopup, setPaymentPopup] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);

  const api = useBackend();

  useEffect(() => {
    api.setting_my_info().then(({ data }) => {
      setUser(data);
      setLoading(false);
    });

    const id = searchParams.get("payment_intent");
    // const status = searchParams.get("redirect_status");
    if (id != null) {
      api.get_payment_intent(id).then(({ data }) => {
        setPaymentIntent(data);
        setPaymentPopup(true);
      });
    }
  }, []);

  return (
    <>
      {loading && <Loader />}

      <Navigation />

      {user && <UserInfo data={user} />}
      <Modal
        open={paymentpopup}
        onClose={() => setPaymentPopup(false)}
        title={`Payment ${paymentIntent?.status}`}
        content={
          <>
            <div className="checkout-item">
              <p className="item-price">
                {`Amount: ${paymentIntent?.amount} ${paymentIntent?.currency}`}
              </p>
            </div>
          </>
        }
      />
      {/* <>
        <div className="grid grid-cols-3 gap-4 mt-2 px-24">
          <div className="user-util-item text-center">
            <div className="text-secondary text-sm">Posts</div>
            <span className="text-md text-w-600">{user?.post_count}</span>
          </div>
          <div className="user-util-item text-center">
            <div className="text-secondary text-sm">Follow</div>
            <span className="text-md text-w-600">{user?.follow_count}</span>
          </div>
          <div className="user-util-item text-center">
            <div className="text-secondary text-sm">Follower</div>
            <span className="text-md text-w-600">{user?.follower_count}</span>
          </div>
        </div>

        <div className="grid grid-cols-5 mt-2 user-actions px-32">
          <div className="col-span-4">
            <button className="btn btn-primary w-100">フォロー</button>
          </div>

          <div className="text-right">
            <button className="btn btn-gray btn-round"></button>
          </div>
        </div>
      </> */}

      <Tabs className="grid grid-cols-2 gap-4 mt-2 px-24 user-tab">
        {/* <Panel title="Posts">
          <Post data={posts} />
        </Panel> */}
        <Panel title="スコア">
          <ScoreTab />
        </Panel>
        <Panel title="ポイント">
          <PointTab wallet={user?.wallet} />
        </Panel>
        {/* <Panel title="Like">
          <Post data={likes} />
        </Panel> */}
      </Tabs>
    </>
  );
}
export default User;
export const userURL = "/user";
