import { useState, useEffect } from "react";
import UserInfo from "../user/UserInfo";
import Navigation from "../hud/Navigation";
import { useBackend } from "../hooks/Backend";
import { useAuthUserContext } from "../auth/AuthContext";
import Tabs from "../common/tab/Tabs";
import Panel from "../common/tab/Panel";
import PointTab from "./PointTab";
import Score from "../user/NewScore";
import Loader from "../common/loader/Loader";
import ScoreTab from "../user/score/ScoreTab";

function Point() {
  const auth = useAuthUserContext();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const api = useBackend();
  useEffect(() => {}, []);

  useEffect(() => {
    api.setting_my_info().then(({ data }) => {
      setUser(data);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Navigation />

      {user && <UserInfo data={user} />}

      <Tabs className="grid grid-cols-2 gap-4 mt-2 px-24 user-tab" selected={1}>
        <Panel title="スコア">
          <ScoreTab />
        </Panel>
        <Panel title="ポイント">
          <PointTab wallet={user?.wallet} />
        </Panel>
      </Tabs>
    </>
  );
}
export default Point;
export const pointURL = "/point";
