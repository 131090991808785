import { useState, useCallback, useEffect } from "react";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useBackend } from "../hooks/Backend";

function SensorSetting() {
  const navigate = useNavigate();
  const api = useBackend();
  const [permissionStatus, setPermissionStatus] = useState("default");
  const [user, setUser] = useState(null);
  const [useGyro, setUseGyro] = useState(false);

  // useEffect(() => {
  //   if (window.DeviceOrientationEvent) {
  //     window.addEventListener("deviceorientation", function (_e) {
  //       setPermissionStatus("granted");
  //     });
  //   }
  // }, []);

  const handleSubmit = () => {
    const formData = user;
    formData.allow_use_gyro = useGyro;
    api
      .update_user_info(formData)
      .then((response) => {
        navigate("/user/setting");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    api.setting_my_info().then(({ data }) => setUser(data));
  }, []);

  useEffect(() => {
    if (user != null && user.allow_use_gyro != null) {
      setUseGyro(user.allow_use_gyro);
    }
  }, [user]);

  const requestSensor = useCallback((e) => {
    if (/iP(hone|(o|a)d)/.test(navigator.userAgent)) {
      DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response === "granted") {
            console.log(e);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  function revokeSensor() {
    setPermissionStatus("default");
    window.removeEventListener("deviceorientation", function (_e) {
      setPermissionStatus("default");
    });
  }

  const savePermission = () => {
    if (permissionStatus === "granted") {
      requestSensor();
    } else {
      revokeSensor();
    }
    navigate("/user/setting");
  };

  return (
    <>
      <div className="text-title flex justify-center items-center mt-100">
        <DevicePhoneMobileIcon className="h-6 w-6" />
        機種の向き・回転に関する設定
      </div>
      <div className="w-full p-56 flex flex-col">
        <div className="my-3 flex-1">
          <div className="form-group text-center">
            <label>利用を許可する</label>
            <div className="d-flex option-input">
              <div
                // className={`${
                //   permissionStatus === "granted" ? "active" : ""
                // } option`}
                // onClick={() => setPermissionStatus("granted")}
                className={`${useGyro ? "active" : ""} option`}
                onClick={() => setUseGyro(true)}
              >
                OK
              </div>
              <div
                // className={`${
                //   permissionStatus !== "granted" ? "active" : ""
                // } option`}
                // onClick={() => setPermissionStatus("default")}
                className={`${!useGyro ? "active" : ""} option`}
                onClick={() => setUseGyro(false)}
              >
                NG
              </div>
            </div>
          </div>
        </div>

        <button
          className="btn btn-primary flex justify-center my-3"
          onClick={() => handleSubmit()}
        >
          送信
        </button>
      </div>
    </>
  );
}
export default SensorSetting;
export const sensorSettingURL = "/sensor-setting";
