import { useState } from "react";
import { useParams } from "react-router-dom";
import { useBackend } from "../hooks/Backend";
import { useRedirect } from "../hooks/Redirect";
import { KeyIcon } from "@heroicons/react/24/outline";
import { useValidate } from "../hooks/Validate";

export default function PasswordResetting(props) {
  const params = useParams();
  const redirect = useRedirect();
  const api = useBackend();
  const validate = useValidate();
  const [pass, setPass] = useState("");
  const [confirm_pass, setConfirmPass] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [showTop, setShowTop] = useState(false)

  const reset_flow = () => {
    setMessage("");

    if (wrong_pass()) return;

    if (pass !== confirm_pass) {
      setError(true);
      setMessage("パスワードと確認用パスワードが一致しません。");
      return;
    }

    const token = params?.token;

    api
      .reset_pass(token, confirm_pass)
      .then((e) => redirect.to_login())
      .catch((e) => {
        if (e.response.data.detail === "VERIFY_USER_ALREADY_VERIFIED")
          redirect.to_top();
        if (e.response.data.detail === "VERIFY_USER_BAD_TOKEN")
          redirect.to_top();
        if (e.response.data.detail === "RESET_PASSWORD_BAD_TOKEN")
          setMessage("URLの期限が切れています！");
        if (e.response.status === 422) return; // validation error
        setError(true);
        setMessage(e.response.data.detail);
      });
  };

  const wrong_pass = () => {
    try {
      validate.password.parse(pass);
    } catch (e) {
      setError(true);
      setMessage(JSON.parse(e.message).map((x) => x.message)[0]);
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="text-title flex justify-center mt-176">
        新パスワード入力
      </div>

      <div className="w-full p-12 flex flex-col">
        {error && message ? (
          <label className="text-[#A72466] text-sm text-center">
            {message}
          </label>
        ) : (
          ""
        )}
        {showTop ? (
          <button
            disabled={confirm_pass.length ? "" : "disabled"}
            className="btn btn-primary flex justify-center my-3"
            onClick={() => redirect.to_user_setting()}
          >
            トップへ
          </button>
        ) : (
          ""
        )}
        <div className="my-3 p-2 flex-1">
          <div className="form-group">
            <span class="input-group-addon">
              <KeyIcon className="h-6 w-6" />
            </span>
            <input
              id="password"
              className="form-control"
              value={pass}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setPass(e.target.value);
              }}
            ></input>
          </div>
        </div>
        <div className="my-3 p-2 flex-1">
          <div className="form-group">
            <span class="input-group-addon">
              <KeyIcon className="h-6 w-6" />
            </span>
            <input
              id="confirm-password"
              className="form-control"
              value={confirm_pass}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setConfirmPass(e.target.value);
              }}
            ></input>
          </div>
        </div>
        <button
          disabled={confirm_pass.length ? "" : "disabled"}
          className="btn btn-primary flex justify-center my-3"
          onClick={() => reset_flow()}
        >
          送信
        </button>
      </div>
    </>
  );
}
export const passResetVerifyUrl = "/reset/verify/:token";
