import { useCallback } from "react";
import axios from "axios";
import qs from "qs";

export const useBackend = () => {
  const api = axios.create({
    withCredentials: true,
  });
  api.defaults.withCredentials = true;
  const register = (email, pass) => {
    return api.post(process.env.REACT_APP_ENDPOINT + "/user/register", {
      email: email,
      password: pass,
    });
  };
  const send_mail_for_register = (email) => {
    return api.post(
      process.env.REACT_APP_ENDPOINT + "/user/request-verify-token",
      {
        email: email, // メール片端から叩いて探られないように必ず202が返る。
      }
    );
  };
  const verify_user = (token) => {
    return api.post(process.env.REACT_APP_ENDPOINT + "/user/verify", token);
  };

  const login = async (username, password) => {
    const form = new FormData();
    form.append("username", username);
    form.append("password", password);
    await api.post(process.env.REACT_APP_ENDPOINT + "/user/login", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const logout = async () => {
    await api.post(process.env.REACT_APP_ENDPOINT + "/user/logout");
  };

  const oauth_registration = async () => {
    const callback_url = await api
      .get(process.env.REACT_APP_ENDPOINT + "/user/authn/authorize")
      .then((e) => e.data.authorization_url);
    window.location.href = callback_url;
  };
  const oauth_register_callback = (code, state) => {
    return api.get(process.env.REACT_APP_ENDPOINT + "/user/authn/callback", {
      params: {
        code: code,
        state: state,
      },
    });
  };
  const oauth_login = async () => {
    const callback_url = await api
      .get(process.env.REACT_APP_ENDPOINT + "/user/authz/authorize")
      .then((e) => e.data.authorization_url);
    window.location.href = callback_url;
  };
  const oauth_login_callback = (code, state) => {
    return api.get(process.env.REACT_APP_ENDPOINT + "/user/authz/callback", {
      params: {
        code: code,
        state: state,
      },
    });
  };
  const show_my_info = () => {
    return axios.get(process.env.REACT_APP_ENDPOINT + "/user/me", {
      withCredentials: true,
    });
  };
  const setting_my_info = () => {
    return axios.get(process.env.REACT_APP_ENDPOINT + "/user/me/setting", {
      withCredentials: true,
    });
  };
  const update_user_info = (user) => {
    return axios.put(
      process.env.REACT_APP_ENDPOINT + "/user/me/setting",
      user,
      {
        withCredentials: true,
      }
    );
  };

  const update_user_icon = (formData) => {
    return axios.put(
      process.env.REACT_APP_ENDPOINT + "/user/me/icon",
      formData,
      {
        withCredentials: true,
      }
    );
  };

  const show_my_timeline = () => {
    return axios.get(process.env.REACT_APP_ENDPOINT + "/user/me/timeline", {
      withCredentials: true,
    });
  };

  const show_list_stage = (keyword) => {
    var url = process.env.REACT_APP_ENDPOINT + "/stage";
    if (keyword != null) {
      url += "?keyword=" + keyword;
    }
    return axios.get(url, {
      withCredentials: true,
    });
  };

  const show_user_score = (userId) => {
    return axios.get(
      process.env.REACT_APP_ENDPOINT + "/user/" + userId + "/score",
      {
        withCredentials: true,
      }
    );
  };

  const show_user_me_score = (userId) => {
    return axios.get(process.env.REACT_APP_ENDPOINT + "/user/me/score", {
      withCredentials: true,
    });
  };

  const toy_action = (toyId) => {
    return axios.get(process.env.REACT_APP_ENDPOINT + "/play/" + toyId, {
      withCredentials: true,
    });
  };

  const show_score_detail = (toyId) => {
    var url = process.env.REACT_APP_ENDPOINT + "/user/me/score/" + toyId;
    return axios.get(url, {
      withCredentials: true,
    });
  };

  const show_stage = (stageId) => {
    var url = process.env.REACT_APP_ENDPOINT + "/stage";
    if (stageId != null) {
      url += "/" + stageId;
    }
    return axios.get(url, {
      withCredentials: true,
    });
  };

  const send_mail_pass_forgot = (email) => {
    return api.post(process.env.REACT_APP_ENDPOINT + "/user/forgot-password", {
      email: email,
    });
  };
  const reset_pass = (token, changed_pass) => {
    return api.post(process.env.REACT_APP_ENDPOINT + "/user/reset-password", {
      token: token,
      password: changed_pass,
    });
  };

  const create_payment_intent = (amount, currency, method) => {
    return api.post(
      process.env.REACT_APP_ENDPOINT + "/payment/create-payment-intent",
      {
        amount: amount,
        currency: currency,
        "payment_method_types[]": method,
      },
      {
        "content-type": "application/x-www-form-urlencoded",
        authorization: "Bearer sk_test_4eC39HqLyjWDarjtT1zdp7dc",
        withCredentials: true,
      }
    );
  };

  const get_payment_intent = (id) => {
    const url = "https://api.stripe.com/v1/payment_intents/" + id;
    const options = {
      method: "GET",
      headers: {
        authorization: "Bearer sk_test_4eC39HqLyjWDarjtT1zdp7dc",
      },
      url,
    };
    return axios(options);
  };
  return {
    register,
    login,
    logout,
    send_mail_for_register,
    verify_user,
    oauth_registration,
    oauth_register_callback,
    oauth_login,
    oauth_login_callback,
    show_my_info,
    setting_my_info,
    update_user_info,
    update_user_icon,
    show_my_timeline,
    show_user_score,
    show_user_me_score,
    show_list_stage,
    toy_action,
    show_score_detail,
    show_stage,
    send_mail_pass_forgot,
    reset_pass,
    create_payment_intent,
    get_payment_intent,
  };
};
