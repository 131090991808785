import { DocumentTextIcon } from "@heroicons/react/24/outline";

export default function TermsOfService() {
  return (
    <>
      <div className="text-title flex justify-center items-center mt-100 text-lg">
        <DocumentTextIcon className="h-6 w-6" />
        利用規約
      </div>
      <div className="w-full p-56 flex flex-col doc">
        <strong>第１条（目的）</strong>
        <p>
          本規約は、株式会社キャリアブロックス（以下「当社」といいます。）が提供するサービス「Peta(ペタ）」を会員（第２条に定義します。）が利用するにあたっての利用条件を定めるものとします。
        </p>
        <hr />
        <strong>第２条（定義）</strong>
        <p>
          本規約における用語の定義は以下のとおりとします。
          <ul>
            <li>
              （１）｢本サービス｣当社が運営する「Peta」において提供されるすべてのサービスをいいます。
            </li>
            <li>
              （２）｢会員｣本規約を承認の上で、当社が指定する手続に従って、本サービスの利用を申し込んだ個人または当社の承諾を得た個人以外の者をいいます。
            </li>
            <li>
              （３）「会員情報」会員が本サービスを利用する上で必要となる、当社が登録または提供を求める情報をいいます。
            </li>
            <li>
              （４）「提供コンテンツ」会員が本サービスで利用するために当社が提供する写真、画像、映像等をいいます
            </li>
          </ul>
        </p>
        <hr />
        <strong>第３条（適用範囲）</strong>
        <p>
          本サービスの提供にあたり、当社が実施する利用条件の告知、および会員に対して発信する通知等も本規約の一部を構成するものとし、当該告知、通知等の内容が本規約の条項と異なる定めをする場合、当該告知、通知等の定めが優先して適用されるものとします。
        </p>
        <hr />
        <strong>第４条（会員登録）</strong>
        <p>
          １．会員となることを希望する者は、希望する者本人が、本規約を承認の上で当社が指定する手続に従って、会員登録申請を行うものとします。
          <br />
          ２．当社は、会員登録申請があった場合には、当社所定の認証手続きを行い、会員登録を行うものとします。ただし、当社は会員登録申請を拒絶することができ、当社は当該登録申請者に対し、拒絶理由を開示する義務を負わないものとします。
          <br />
          ３．前項に規定する会員登録をもって、会員と当社との間において、本規約を内容とする利用契約が成立するものとします。なお、当社が不適当と判断する場合には、会員登録後であっても会員登録の承諾を取り消す場合があります。
          ただし、当社は承諾を取り消した理由について回答する義務を負いません。
          <br />
          ４．会員は前項に規定する会員登録をもって、本サービスを利用することができるようになります。本サービスは無料会員と有料会員が存在し、会員の種類によって閲覧可能なコンテンツ、利用可能な機能が異なります。
        </p>
        <hr />
        <strong>第５条（支払方法・利用期間）</strong>
        <p>
          １．会員は、有料コンテンツ及び本サービスにかかる当社の定める利用料金を利用する場合、以下のいずれかの方法によって支払うものとします。なお、当社の定める利用料金は変更されることがあります。変更時には、登録した電子メールアドレスへの通知又は本サイト上での告知をもって会員に通知するものとします。
          <br />
          ・当社の定めるクレジットカードを利用しての決済
          <br />
          ２．会員から各クレジットカード会社等への支払い日等は、各クレジットカード会社等との契約の定めにより異なります。
        </p>
        <hr />
        <strong>第６条（本規約の変更）</strong>
        <p>
          １．当社は、必要に応じて本規約を変更することができるものとします。なお、当該変更された規約は、本サービス上において発表、もしくは当社が適当と判断する方法により通知するものとし、発表もしくは通知されたのち一週間経過した時点で、その期間内に会員が退会手続きを行わない限り、vによって承諾されたものとみなし、その時点をもって会員との、当該変更された規約を内容とする利用契約も有効に成立したものとみなします。
          <br />
          ２．当社は、将来において本サービスの事業を継承する会社に、本規約上の当社の地位を譲渡する場合があります。この場合の地位の譲渡の手続には、前項の規定を準用するものとします。
        </p>
        <hr />
        <strong>第７条（変更の届出）</strong>
        <p>
          １．会員情報の変更を希望する場合には、当社が別途指示する方法により届け出るものとします。
          <br />
          ２．会員が前項の届出を怠ったことにより不利益を被った場合であっても､当社は一切の責任を負わないものとします。
        </p>
        <hr />
        <strong>第８条（会員登録の取消等）</strong>
        <p>
          １．会員が以下の各号の一に該当する場合、当社は、事前に通知することなく、直ちに会員による本サービスの全部または一部の利用を停止、または会員登録を取り消し、もしくは／かつ利用契約の解除をすることができるものとます。なお、当社は、当該措置の理由を開示する義務を負わないものとします。
          <ul>
            <li>
              （１）第１４条第１項および第２項の行為、またはそのおそれのある行為を行ったと当社が判断した場合
            </li>
            <li>（２）第三者になりすまして会員登録を行った場合</li>
            <li>
              （３）未成年者、成年被後見人、被保佐人または被補助人のいずれかである場合で、会員登録申請が成年後見人によって行われておらず、または会員登録申請の際に法定代理人、保佐人もしくは補助人の同意を得ていない場合。
            </li>
            <li>
              （４）その他、会員として不適切もしくは登録の継続が困難であると当社が判断した場合。
            </li>
          </ul>
          ２．前項により会員登録の取消もしくは利用契約の解除を受けた場合において、当該会員が再度会員登録申請をした場合、当社は、当該申請を承諾しない場合があります。
          <br />
          ３．当社は、第１項により会員登録を取消した会員の会員プロフィールの全部または一部を削除することができるものとし、当社は、かかる措置により会員に生じた不利益、損害について、一切の責任を負わないものとします。
          <br />
          ４．前３項の規定は、当社による会員に対する損害賠償の請求を妨げるものではありません。
        </p>
        <hr />
        <strong>第９条（退会）</strong>
        <p>
          １．会員は、退会する場合は、当社が指定する退会手続を経ることとします。
          <br />
          ２．前条第２項および第３項は、本条に基づき退会した場合にも適用されるものとします。
        </p>
        <hr />
        <strong>第１０条（会員情報の管理）</strong>
        <p>
          １．会員は、本サービスの会員情報を、自己の責任にて管理するものとします。
          <br />
          ２．会員は、本サービスの会員情報を任意のメールアドレスおよびパスワードにて管理することができます。会員は、自己が登録したメールアドレスおよびパスワードを自己の責任にて管理及び保管するものとします。
          <br />
          ３．メールアドレスおよびパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任は会員が負うものとし、当社は一切責任を負いません。
          <br />
          ４．会員情報を通じた本サービスの利用については、会員本人以外の第三者による利用および行為の場合も含め、すべて当該会員による利用および行為とみなします。当該会員は、当該利用および行為により生じた一切の債務を負担するものとします。
          <br />
          ５．会員は、メールアドレスおよびパスワードが不当に取得され、あるいは会員情報が第三者により利用されるおそれがある場合は、直ちに当社にその旨連絡するとともに、当社からの指示がある場合には、これに従うものとします。
        </p>
        <hr />
        <strong>第１１条（知的財産権等）</strong>
        <p>
          １．本サービスおよび本サービスにおいて当社が会員に提供する提供コンテンツの著作権その他の権利は、有償であると無償であるとを問わず、当社または当該権利を有する第三者に帰属します。会員は、本サービスにおいて利用する目的で、提供コンテンツの非独占的使用権を、当社または当該権利を有する第三者より許諾されるものとします。
          <br />
          ２．会員は、本サービスにおいて自己が利用するため、自己の判断により本サービスに会員プロフィールを登録することができるものとします。なお、当該ユーザープロフィールに他者が権利を有する知的財産権等が含まれる場合には、会員が当該権利者との権利許諾等に関する手続を行うものとします。
          <br />
          ３．会員が前２項の規定に違反することにより何らかの問題が発生した場合には、会員は、自己の費用と責任においてかかる問題を解決するものとし、当社に何等の迷惑または損害を与えないものとします。
        </p>
        <hr />
        <strong>第１２条（禁止事項）</strong>
        <p>
          会員は、以下の行為を行ってはならないものとします。
          <br />
          ・当社が提供するアプリケーション、当社が保有するサーバー及びこれらが生成する情報、通信内容等の解読、解析、逆コンパイル、逆アセンブルまたはリバースエンジニアリング
          <br />
          ・他の会員もしくはサービス提供者の個人情報、または他の会員記述情報を違法、不適切に収集、開示その他利用すること
          <br />
          ・他の個人または団体になりすまし、または他の個人または団体と関係があるように不当に見せかけること
          <br />
          ・他の会員またはサービス提供者のID、パスワードを入手しようとすること
          <br />
          ・迷惑メール、チェーンメール、ウィルス等の不適切なデータを送信すること
          <br />
          ・ボットなどの自動化された手段を用いて本サービスを利用すること
          <br />
          ・本サービスを変更または妨げることを目的に利用すること
          <br />
          ・本サービスのバグ、誤動作を利用すること ・詐欺的行為をすること
          <br />
          ・その他当社が不適当と判断するもの
        </p>
        <hr />
        <strong>第１３条（本サービスの変更・中断・中止・終了）</strong>
        <p>
          １．当社は、会員に事前に通知することなく、本サービスの一部または全部を変更、追加、することができます。
          <br />
          ２．当社は、以下の各号の一に該当する事由が生じた場合には、当社の判断により、本サービスの運用の全部または一部を中断または中止することができるものとします。
          <ul>
            <li>
              （１）戦争、暴動、騒乱、労働争議、天災、地震、噴火、洪水、津波、火災、停電などの不可抗力により本サービスが通常どおり提供できなくなった場合。
            </li>
            <li>
              （２）本サービスの運営において必要なシステム等の一時的または大規模なメンテナンス、電気通信設備の障害、プロバイダー等の障害、システムトラブルなどによる緊急な保守点検を行う場合。
            </li>
            <li>
              （３）その他やむを得ない事由が生じた場合など、当社が必要と判断した場合
            </li>
          </ul>
          ３．当社は、前項により本サービスの全部または一部を中断または中止する場合は、当社が適当と判断する方法で事前に告知します。ただし、緊急の場合には、この限りではありません。
          <br />
          ４．当社は、本サービスの全部を終了する場合には、当社が適当と判断する方法で、事前に会員にその旨を告知します。
          <br />
          ５．当社は理由の如何を問わず、前４項の規定により会員が何らかの不利益を受けた場合であっても、損害賠償責任を含めた一切の責任を負いません。
        </p>
        <hr />
        <strong>第１４条（設備等）</strong>
        <p>
          １．当社の設備もしくは本サービスの運営設備に障害を生じ、またはその設備が滅失したことを当社が知ったときは、当社は速やかにその設備を修理・復旧するよう努めるものとします。かかる場合に、会員または第三者に損害が生じた場合であっても、当社は一切責任を負わないものとします。
          <br />
          ２．会員は、自己の負担と責任により、本サービスの利用に必要な通信機器、ソフトウェアその他の機器および通信回線その他の接続環境等を用意、設置、操作し、本サービスを利用可能な状態にするものとします。
        </p>
        <hr />
        <strong>第１５条（個人情報の取扱い）</strong>
        <p>
          １．当社は、法令に定める場合を除き、事前に会員である本人の同意を得ることなく、会員情報に含まれている個人情報を第三者へ開示、提供することはありません。ただし、以下の場合は除きます。
          <ul>
            <li>
              （１）法令に基づく場合など個人情報保護法に定める場合や、裁判所・警察等の公的機関から法律に基づく要請を受けた場合。
            </li>
            <li>（２）会員の事前の承認、同意を得た場合。</li>
            <li>
              （３）本サービスを提供する上で必要となる業務委託先に開示する場合。
            </li>
            <li>
              （４）当社の合併、分社化、事業譲渡により事業が継承され会員情報を引き継ぐ場合。
            </li>
          </ul>
          ２.当社は、会員情報を、以下に掲げる目的により、無償で、複製その他の方法により利用し、第三者に提供することができるものとし、会員はこれを承諾するものとします。
          <ul>
            <li>
              （１）本サービス実施、提供のため
              <br />
              a.本サービスの拡充、改善、新規開発のための調査・分析のため
              <br />
              b.お問い合わせ、サポート対応のため
              <br />
              c.その他、お客様の同意を得た利用目的のため
            </li>
            <li>
              （２）本サービスの利用状況等に係る統計情報の作成、本サービスまたは当社の運営するウェブサイト等における公表のため。
            </li>
          </ul>
          ３．当社は、会員登録情報の保存義務を負うものではありません。会員は、必要な会員情報および会員プロフィールについて、自らの責任でバックアップをとるものとします。
          <br />
          ４．当社は、会員が本サービスを退会し、または当社が本サービスの運営を終了したときは、当社の判断により、会員登録情報を全て消去できるものとし、その返却等の要望には一切応じません。また、当社はこれによって会員に生じる不利益、損害等について、一切の責任を負いません。
          <br />
          ５．個人情報を当社にご提供いただくか否かは、会員ご自身の任意です。個人情報を登録するか否かは、会員ご自身で判断ください。ただし、当社が本サービスを提供するうえでお願いする個人情報をご提供いただけない場合や、ご提供いただいた個人情報に不備があった場合には、個人情報の取得目的どおりのサービスがご提供できない場合があります。
          <br />
          ６．本サービスでは、クッキーを使用する場合があります。これは本サービスの改善や利用状況の解析のため、会員によるWEBサイト、アプリ、広告の閲覧状況を確認するもので、統計的なマーケティング情報として利用いたします。クッキー内に個人情報が含まれることはございません。また、個人情報の取得および会員情報との関連付けを行うものではありません。
          なお、本サービスでは、下記の事業者が提供する情報収集モジュールを使用し、当該事業者を通じて情報を自動取得します。
          <br />
          ７．当社は、会員からご提供いただいた個人情報の利用目的の通知、開示、訂正、追加、削除、利用停止または第三者提供停止の請求に対応いたします。個人情報の取り扱いに関するお問い合わせ、苦情などは以下のお問い合わせ先までお願いいたします。
          株式会社キャリアブロックス 〒105-0014 東京都港区芝1-9-2
          ベルメゾン芝1001 メール：info@careerblocks.jp
        </p>
        <hr />
        <strong>第１６条（反社会的勢力の排除）</strong>
        <p>
          1.
          当社および会員は，現在，暴力団，暴力団員，暴力団準構成員，暴力団関係企業，総会屋，社会運動等標榜ゴロまたは特殊知能暴力集団等，その他これに準ずる者(以下，「反社会的勢力」という)のいずれでもなく，また，反社会的勢力が経営に実質的に関与している法人等に属する者ではないことを表明し，かつ将来にわたっても該当しないことを確約する。
          <br />
          2.
          当社および会員は，相手方が次の各号のいずれかに該当する場合，何らの催告をすることなく契約を解除することができ，相手方に損害が生じてもこれを賠償することを要しない。
          <ul>
            <li>（1） 反社会的勢力に該当すると認められるとき</li>
            <li>（2） 相手方が反社会的勢力を利用していると認められるとき</li>
            <li>
              （3）
              相手方の経営に反社会的勢力が実質的に関与していると認められるとき
            </li>
            <li>
              （4）
              相手方または相手方の役員もしくは相手方の経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有しているとき
            </li>
            <li>
              {" "}
              （5）
              相手方が反社会的勢力に対して資金等を提供し，または便宜を供与するなどの関与をしていると認められるとき
            </li>
            <li>
              （6）
              自らまたは第三者を利用して，暴力的な要求行為，法的な責任を超えた不当な要求行為，脅迫的な言動，暴力および風説の流布・偽計・威力を用いた信用棄損・業務妨害その他これらに準ずる行為に及んだとき
            </li>
          </ul>
        </p>
        <hr />
        <strong>第１７条（免責事項）</strong>
        <p>
          １．当社は、本サービスの内容、本サービスにより提供する一切の情報、データ等について、その完全性、正確性、適法性、目的適合性等いかなる保証も行わず、一切の責任を負いません。
          <br />
          ２．当社は、当社が本サービスにより提供する一切の情報、データ等、または会員が掲載する一切の情報、データ等を確実に保存することを保証するものではなく、かかる情報、データ等が消失したとしても、一切の責任を負いません。
          <br />
          ３．本サービスの提供、遅滞、変更、中止もしくは廃止、本サービスを通じて登録、提供される情報等の流失もしくは消失等、およびその他本サービスに関連して発生した会員および第三者の損害について、当社は一切の責任を負いません。
          <br />
          ４．本サービスの利用に関連して、会員が他の会員もしくは第三者に対して損害を与えた場合、または会員が他の会員もしくは第三者と紛争を生じた場合、当該
          会員は自己の費用と責任で解決するものとし、当社に何等の迷惑または損害を与えないものとします。また、会員が本サービスの利用に関連して当社に損害を生
          じさせた場合は、当該会員は当社に生じた損害を賠償するものとします。
          <br />
          ５．当社は、本サービスを提供するコンピューターシステムの稼働状況について保証するものではなく、本サービス提供のためのコンピューターシステムの障害等、いかなる原因に基づき生じた損害についても、一切の責任を負いません。
          <br />
          ６．当社は、本サービスの利用、本サイトからのダウンロード、コンピュータウイルス感染等により、コンピュータ、周辺機器、回線、ソフトウェアなどに生じた損害について、一切の責任を負いません。
          <br />
          ７．本規約に従い当社がユーザーに対して損害賠償の責任を負う場合であっても、当社に故意又は重過失が存する場合を除き、当社が賠償すべき損害の範囲は、会員に直接かつ現実的に生じた損害に限定されます。
        </p>
        <hr />
        <strong>第１８条（管轄裁判所）</strong>
        <p>
          １．本サービスに関連して会員と当社との間で問題が生じた場合には、会員と当社で誠意をもって協議するものとします。
          <br />
          ２．協議しても解決せず訴訟となった場合、東京地方裁判所の採決に基づき厳正に対処するものとし、それに合意します。
        </p>
        <hr />
        <strong>第１９条（準拠法）</strong>
        <p>本規約に関する準拠法は、日本法とします。</p>
        <hr />
        <strong>付則</strong>
        <p>本規約は令和5年4月1日から実施します。</p>
      </div>
    </>
  );
}
export const termURL = "/terms";
