import React, { memo } from "react";
import SimpleImageAdsense from "../adsense/SimpleImageAdsense";

const d2r = (d) => d * (Math.PI / 180);
const cos = (d) => Math.cos(d2r(d));
const sin = (d) => Math.sin(d2r(d));
const randomize = (max, min) => Math.random() * (max - min) + min;

const Image = (props) => {
  const theta = props.theta; // 上に最大20度見上げる、下に最大30度見下ろす
  const phi = props.phi;
  const place = {
    farness: props.distance * sin(theta) * cos(phi),
    height: props.distance * cos(theta),
    how_far_right: props.distance * sin(theta) * sin(phi),
    pitch: 0,
    yaw: -phi,
    roll: 0,
  };
  return <SimpleImageAdsense {...place} alwaysLookAtCamera={false} show={props.show} image={props.image} w={props.w} h={props.h} />;
};

export default memo(Image);
