import {React, useEffect, useRef, useState} from "react";
import Modal from "../common/Modal";
import gif from "../image/gacha_zenhan.mp4";
import perf from "../image/gacha_kouhan.mp4";
import perf_rare from "../image/gacha_kouhan_g.mp4";
import { useNavigate } from "react-router-dom";
import { useImage } from "../hooks/Image";
import { Loader } from "@react-three/drei";

function ToyDetalModal(props) {
  const { toy, gacha, loading, open, setOpen, toyId, isRare } = props;
  const navigate = useNavigate();
  const { error, image } = useImage("rarity_frame/" + toy?.rank + ".png");
  // stateを使うとちらつくので極力抑えるためにvisibilityで制御するためのref
  // 見えないけどある状態にしてnodeの追加削除のラグをなくす目的（そんな気になるか？）
  const loadRef = useRef(null);
  const perfNormal = useRef(null);
  const perfRare = useRef(null);
  const bgRef=useRef(null);
  const uiRef = useRef(null);
  const showLoad = () => {
    uiRef.current.classList.add("hide")
    uiRef.current.classList.remove("show")
    bgRef.current.classList?.remove("bg-white")
    loadRef.current.classList?.remove("hide")
    loadRef.current.classList?.add("show")
  }
  const showNormal = () => {
    loadRef.current.classList?.remove("show")
    loadRef.current.classList?.add("hide")
    perfNormal.current.classList?.remove("hide");
    perfNormal.current.classList?.add("show");
  }
  const showRare = () => {
    loadRef.current.classList?.remove("show")
    loadRef.current.classList?.add("hide")
    perfRare.current.classList?.remove("hide");
    perfRare.current.classList?.add("show");
  }
  const showPrize = () => {
    perfNormal.current.classList?.remove("show");
    perfNormal.current.classList?.add("hide");
    perfRare.current.classList?.remove("show");
    perfRare.current.classList?.add("hide");
    uiRef.current.classList.add("show")
    uiRef.current.classList.remove("hide")
    bgRef.current.classList?.add("bg-white")
  }
  
  const play = async () => { 
    // 可視不可視を切り替えてそれぞれ最初から再生する。
    // 再生停止だけを制御して切替時のちらつきをなくす目的（そんな気になる？）
    showLoad()
    loadRef.current.currentTime = 0;
    console.log(loadRef, loadRef.current)
    let p = await loadRef.current.play()
    console.log(p)
    await Promise.all([ld(), gacha(toyId)]).then(async e => { // 再生終了とAPI通信の両方が終わったタイミングで演出切替
      console.log(e)
      if (e[1]?.rank === "UR" || e[1]?.rank === "SSR" || e[1]?.rank === "Ex") {// 文字列比較のorが最速？とにかく早く判断して欲しい
        showRare();
        perfRare.current.currentTime = 0;
        await perfRare.current.play();
      } else {
        showNormal();
        perfNormal.current.currentTime = 0;
        await perfNormal.current.play();
      }
    })
      await pf().then(e => {
        showPrize();
      })
  }
  const ld = () => {
    return new Promise((rs, rj) => {
      loadRef.current.addEventListener('ended', () => {
        rs();
      })
    })
  }
  const pf = () => {
    return new Promise((rs, rj) => {
      perfNormal.current.addEventListener('ended', () => {
        rs();
      });
      perfRare.current.addEventListener('ended', () => {
        rs();
      })
    });
  }

  return (
    <Modal
      className="toy-detail-modal"
      open={open}
      onClose={() => setOpen(false)}
      transparent
      content={
        <div ref={bgRef} className="rounded-lg shadow-lg bg-white">
          <video className="loader hide" ref={perfNormal} src={perf} alt="loading..."  loop={false} muted playsInline preload="auto"/>
          <video className="loader hide" ref={perfRare} src={perf_rare} alt="loading..."  loop={false} muted playsInline preload="auto"/>
          <video className="loader hide" ref={loadRef} src={gif} alt="loading..."  loop={false} muted playsInline preload="auto"/>
          <div ref={uiRef}>
            <div className="toy-content z-[300000]">
              <div className="toy-body">
                <h1 className="title">{toy?.name}</h1>
              </div>

              <div className="toy-action">
                <div className="toy-action-item text-left">
                  <a onClick={() => setOpen(false)}>
                    他のガチャを
                    <br />
                    探す <br />
                    <span>←</span>
                  </a>
                </div>
                <div className="toy-action-item text-center m-auto">
                  <a onClick={() => play()}>
                    <p>
                      ガチャを 引く
                      <br />
                      <span>↓</span>
                    </p>
                  </a>
                </div>
                <div className="toy-action-item text-right">
                  <a className="ml-auto" onClick={() => navigate("/user")}>
                    獲得一覧を
                    <br />
                    見る <br />
                    <span>→</span>
                  </a>
                </div>
              </div>
            </div>
              
            <div class="toy-layer"></div>
            <div class="image-frame">
              {!loading && image ? <img src={image} alt="user ava" /> : null}
            </div>
            <img src={toy?.image} alt="Logo" />
          </div>
        </div>
      }
    />
  );
}

export default ToyDetalModal;
