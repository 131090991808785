import Image from "./Image";
import a from "./images/a.png"
import b from "./images/b.png"
import c from "./images/c.png"
import d from "./images/d.png"
import e from "./images/e.JPG"
import f from "./images/f.JPG"
import g from "./images/g.JPG"
import h from "./images/h.JPG"
import i from "./images/i.JPG"
import j from "./images/j.JPG"
import k from "./images/k.JPG"
import l from "./images/l.JPG"
import m from "./images/m.JPG"
import n from "./images/n.JPG"
import o from "./images/o.PNG"
import p from "./images/p.JPG"
import q from "./images/q.JPG"
import r from "./images/r.JPG"
import s from "./images/s.JPG"

function IdolMihonIchi(props) {
const randomize = (max, min) => Math.random() * (max - min) + min;
return (
    <>
    {props.show && (
        <>
              <Image show={true} distance={50} image={a} w={1684} h={856} phi={0} theta={80}/>
              <Image show={true} distance={50} image={b} w={3869} h={466} phi={0} theta={100}/>

              <Image show={true} distance={40} image={c} w={573} h={781} phi={80} theta={90}/>
              <Image show={true} distance={40} image={d} w={572} h={781} phi={110} theta={90}/>

              <Image show={true} distance={60} image={e} w={681} h={622} phi={-70} theta={80}/>
              <Image show={true} distance={60} image={f} w={1138} h={788} phi={-80} theta={80}/>
              <Image show={true} distance={60} image={g} w={1107} h={908} phi={-90} theta={80}/>
              <Image show={true} distance={60} image={h} w={1282} h={945} phi={-100} theta={80}/>
              <Image show={true} distance={60} image={i} w={1723} h={951} phi={-110} theta={80}/>

              <Image show={true} distance={60} image={j} w={1920} h={1080} phi={-70} theta={90}/>
              <Image show={true} distance={60} image={k} w={1800} h={1800} phi={-80} theta={90}/>
              <Image show={true} distance={60} image={l} w={2048} h={1366} phi={-90} theta={90}/>
              <Image show={true} distance={60} image={m} w={1170} h={1170} phi={-100} theta={90}/>
              <Image show={true} distance={60} image={n} w={1478} h={1108} phi={-110} theta={90}/>

              <Image show={true} distance={60} image={o} w={2000} h={1414} phi={-70} theta={100}/>
              <Image show={true} distance={60} image={p} w={4535} h={3206} phi={-80} theta={100}/>
              <Image show={true} distance={60} image={q} w={842} h={595} phi={-90} theta={100}/>
              <Image show={true} distance={60} image={r} w={1023} h={815} phi={-100} theta={100}/>
              <Image show={true} distance={60} image={s} w={1568} h={1046} phi={-110} theta={100}/>
            </>
    )}
    </>
);
}
export default IdolMihonIchi;