import * as THREE from "three";

const d2r = (d) => d * (Math.PI / 180);

export const useAxes = () => {
  const vector3 = (farness, height, how_far_right) => {
    return /iP(hone|(o|a)d)/.test(navigator.userAgent)
      ? new THREE.Vector3(how_far_right, height, -farness)
      : new THREE.Vector3(farness, height, how_far_right);
  };
  const euler = (pitch, yaw, roll) => {
    return /iP(hone|(o|a)d)/.test(navigator.userAgent)
      ? new THREE.Euler(d2r(pitch), d2r(yaw), d2r(roll), "YXZ")
      : new THREE.Euler(d2r(pitch), d2r(yaw - 90), d2r(roll), "YXZ");
  };
  return [vector3, euler];
};
