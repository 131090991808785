import { useCallback } from "react";
import { useNavigate } from "react-router-dom"
import { loginURL } from "../auth/Login";
import { mainViewURL } from "../MRRootView";
import { landingURL } from "../static_pages/Landing";
import { timelineURL } from "../post/TimeLine";
import { userSettingURL } from "../user/UserSetting";
export const useRedirect = () => {
    const to = useNavigate();
    const to_top = useCallback( () => to(landingURL), [])
    const to_login = useCallback( () => to(loginURL), [])
    const to_main_view = useCallback( () => to(mainViewURL), [])
    const to_timeline= useCallback( () => to(timelineURL), [])
    const to_mail_send = useCallback( () => to(), [])
    const to_user_setting = useCallback(() => to(userSettingURL), [])
    return {to_top, to_login, to_main_view, to_timeline, to_user_setting}
}