import React from "react";
import loader from "../../image/peta-logo.png";

const Loader = () => {
  return (
    <div className="pre-loader">
      <div class="image-layer"></div>
      <div className="loader-img">
        <img src={loader} />
      </div>
    </div>
  );
};

export default Loader;
