import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImage } from "../../../hooks/Image";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";

function StagePreview(props) {
  const navigate = useNavigate();
  const { title, catchphrase, place, time, detail, eyecatchImage } = props;

  const { loading, error, image } = useImage(eyecatchImage);
  const imageUrl = eyecatchImage;

  return (
    <div
      className={`post-item`}
      //   onClick={() => goToDetail(data.id)}
    >
      <div class="image-layer"></div>
      <img src={imageUrl} alt="Logo" />
      {/* {data?.eyecatch_bgr ? (
        <div className={`post-item-bgr ${data?.eyecatch_bgr}`}></div>
      ) : null} */}
      <div className="post-content">
        <div className="post-title">{catchphrase}</div>
        <div className="post-time">{time}</div>
      </div>
      <div className="post-footer">
        <div className="d-flex">
          <div className="post-location">{place}</div>
          <div className="post-action">
            <div className="post-action-item right">
              <h2 className="text-sm text-w-800 ">見る</h2>
              <ArrowLeftOnRectangleIcon
                className="h-6 w-6 text-white"
                style={{ transform: "rotate(180deg)" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StagePreview;
