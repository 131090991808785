// import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PhotoIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

function StageInfo(props) {
  const { data, stageCatchphrase } = props;
  const navigate = useNavigate();

  return (
    <>
      <div class="grid grid-cols-3">
        <div class="col-start-1 col-end-7 page-header">
          <div class="image-layer"></div>
          <img alt="user image" class="bg-image" src={data?.image}></img>
        </div>
        <div className="col-start-1 col-end-7 user-name-container">
          <div className="text-title text-md flex justify-center">
            <h2>{stageCatchphrase}</h2>
          </div>
          <div className="flex justify-center text-[20px] text-w-800 ">
            <h2>{data?.name}</h2>
          </div>
        </div>
      </div>
    </>
  );
}
export default StageInfo;
