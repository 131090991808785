import { useState, useEffect } from "react";
import EventData from "./event_data.json";

function EventDetail(props) {
  const { onClose, data } = props;
  // const [post, setPost] = useState([]);
  // useEffect(() => {
  //   if (EventData) {
  //     setPost(EventData.stage);
  //   }
  // }, [EventData]);

  return (
    <div className="post-layer" onClick={() => onClose()}>
      <div className="brg-layer" />
      <div className="post-content">
        <div className="title">{data?.catchphrase}</div>
        <div className="sub-title text-right">{data?.name}</div>
        <p>{data?.instruction}</p>
        <div className="hashtag">
          #プロモーション大作戦 #アイドル #地下アイドル
        </div>
        <div className="location">{data?.place?.name}</div>
        <div className="time">10/31 12:00 ~ 11/2 23:00</div>
      </div>
    </div>
  );
}
export default EventDetail;
