import { useState } from "react";
import Navigation from "../hud/Navigation";
import ava from "../../components/image/user/ava1.png";
import ticket_1 from "../../components/image/ticket/ticket_1.png";
import ticket_2 from "../../components/image/ticket/ticket_2.png";
import ticket_3 from "../../components/image/ticket/ticket_3.png";
import ticket_4 from "../../components/image/ticket/ticket_4.png";

import {
  DevicePhoneMobileIcon,
  MapPinIcon,
  HeartIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import { VideoCameraIcon } from "@heroicons/react/24/solid";

function TicketDetail() {
  return (
    <>
      <Navigation />
      <div className="flex-1 ticket-page">
        <div className="timeline-header">
          {/* <h3 className="text-md text-w-800">Timeline</h3> */}
          <div className="action">
            <div className="action-item">
              <VideoCameraIcon className="h-6 w-6" />
            </div>
            <div className="action-item">
              <DevicePhoneMobileIcon className="h-6 w-6" />
            </div>
            <div className="action-item">
              <MapPinIcon className="h-6 w-6" />
            </div>
            <div className="action-item">
              <div className="user-ava">
                <img src={ava} alt="user ava" />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 post-container">
          <div className="col-span-1 gap-4">
            <div className="post-item ticket">
              <div className="post-content">
                <div className="post-title">
                  運命のV2リーグ最終戦サフィルヴァが勝ちますように。
                </div>
              </div>
            </div>
            <div className="post-images grid grid-cols-4">
              <img src={ticket_1} alt="Logo" />
              <img src={ticket_2} alt="Logo" />
              <img src={ticket_3} alt="Logo" />
              <img src={ticket_4} alt="Logo" />
            </div>
            <div className="d-flex">
              <div className="post-location">
                <img src={ticket_1} alt="Logo" />
                サフィルヴァファン
              </div>
              <div className="post-action">
                <div className="post-action-item">
                  <ArrowPathRoundedSquareIcon className="h-6 w-6" />
                  <span>1,430</span>
                </div>
                <div className="post-action-item">
                  <HeartIcon className="h-6 w-6" />
                  <span>1,430</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TicketDetail;
export const ticketDetailURL = "/ticket-detail";
