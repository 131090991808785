import { useState, useEffect } from "react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useBackend } from "../hooks/Backend";

function LocationSetting() {
  const navigate = useNavigate();
  const api = useBackend();
  const [permissionOnPostStatus, setPermissionOnPostStatus] =
    useState("default");
  const [permissionOnSearchStatus, setPermissionOnSearchStatus] =
    useState("default");

  const [user, setUser] = useState(null);
  const [possionOnSearch, setPossionOnSearch] = useState(false);
  const [possionOnPost, setPossionOnPost] = useState(false);
  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     setPermissionOnSearchStatus("granted");
  //   }
  // }, []);

  const handleSubmit = () => {
    const formData = user;
    formData.allow_send_position_on_search = possionOnSearch;
    formData.allow_send_position_on_post = possionOnPost;
    api
      .update_user_info(formData)
      .then((response) => {
        navigate("/user/setting");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    api.setting_my_info().then(({ data }) => setUser(data));
  }, []);

  useEffect(() => {
    if (user != null && user.allow_send_position_on_search != null) {
      setPossionOnSearch(user.allow_send_position_on_search);
    }
    if (user != null && user.allow_send_position_on_post != null) {
      setPossionOnPost(user.allow_send_position_on_post);
    }
  }, [user]);

  function requestLocationPermission() {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
  }

  const savePermission = () => {
    if (
      permissionOnPostStatus === "granted" ||
      permissionOnSearchStatus === "granted"
    ) {
      requestLocationPermission();
    } else {
      // revokeSensor();
    }
    navigate("/user/setting");
  };

  return (
    <>
      <div className="text-title flex justify-center items-center mt-100">
        <MapPinIcon className="h-6 w-6" />
        位置情報に関する設定
      </div>
      <div className="w-full p-56 flex flex-col">
        <div className="my-3 flex-1">
          <div className="form-group text-center">
            <label>検索するときに位置情報を提供します。</label>
            <div className="d-flex option-input">
              <div
                className={`${possionOnSearch ? "active" : ""} option`}
                onClick={() => setPossionOnSearch(true)}
              >
                OK
              </div>
              <div
                className={`${!possionOnSearch ? "active" : ""} option`}
                onClick={() => setPossionOnSearch(false)}
              >
                NG
              </div>
            </div>
          </div>
        </div>
        {/* <div className="my-3 flex-1">
          <div className="form-group text-center">
            <label>位置情報を含めた投稿を行います。</label>
            <div className="d-flex option-input">
              <div
                className={`${possionOnPost ? "active" : ""} option`}
                onClick={() => setPossionOnPost(true)}
              >
                OK
              </div>
              <div
                className={`${!possionOnPost ? "active" : ""} option`}
                onClick={() => setPossionOnPost(false)}
              >
                NG
              </div>
            </div>
          </div>
        </div> */}

        <button
          className="btn btn-primary flex justify-center my-3"
          onClick={() => handleSubmit()}
        >
          送信
        </button>
      </div>
    </>
  );
}
export default LocationSetting;
export const locationSettingURL = "/location-setting";
