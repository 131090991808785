// import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PhotoIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../common/upload/ImageUpload";
import { useBackend } from "../hooks/Backend";

function UserInfo(props) {
  const { isEdit, data } = props;
  const api = useBackend();
  const navigate = useNavigate();

  const handleUploadImage = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    api
      .update_user_icon(formData)
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <div class="grid grid-cols-3">
        <div class="col-start-1 col-end-7 page-header">
          <div class="image-layer"></div>
          <img alt="user image" class="bg-image" src={data?.icon}></img>
          {isEdit ? (
            <div className="btn-user-upload">
              {/* <PhotoIcon className="h-6 w-6 text-white" /> */}
              <ImageUpload name="icon" handleUpload={handleUploadImage} />
            </div>
          ) : (
            <Link to="/user/setting" className="btn-user-edit">
              <Cog6ToothIcon className="h-6 w-6" />
            </Link>
          )}
        </div>
        <div className="col-start-1 col-end-7 user-name-container">
          {data?.name != null ? (
            <div className="text-title text-md flex justify-center">
              {data?.name}
            </div>
          ) : (
            <div className="text-title text-md flex justify-center">
              ユーザー名が未設定です
            </div>
          )}
          {/* {data.user_id != null ? (
            <div className="text-secondary text-sm text-w-600 flex justify-center">
              @{data.user_id}
            </div>
          ) : (
            <div className="text-secondary text-sm text-w-600 flex justify-center">
              IDが未設定です
            </div>
          )} */}
          {isEdit && (
            <button
              className="d-flex text-sm text-w-600"
              onClick={() => navigate("/change-name")}
            >
              <ChevronRightIcon className="h-6 w-6" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
export default UserInfo;
