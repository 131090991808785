import { useEffect, useState } from "react";
// import image_1 from "../image/post/post_1.png";

export const useImage = (fileName) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);

  const fetchImage = async () => {
    try {
      const response = await import(`../image/${fileName}`); // change relative path to suit your needs
      // console.log(response);
      setImage(response.default);
    } catch (err) {
      // console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImage();
  }, [fileName]);

  return {
    loading,
    error,
    image,
  };
};
