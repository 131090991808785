import { useAuthUserContext } from "./AuthContext";
import { Navigate, useLocation } from "react-router";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "../common/Modal";

export const AuthGuard = (props) => {
  const auth = useAuthUserContext();
  const navigate = useNavigate();

  const location = useLocation();
  const returnUrl = location.pathname + location.search;
  // Add a 401 response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error.response.status) {
        if (error.response?.data?.code == "REACH_LIMIT") {
        } else {
          auth.setUser(null);
        }

        // navigate("/login?returnUrl=" + returnUrl);
        //need login
        // setOpenAuthPopup(true);
      } else {
        return Promise.reject(error);
      }
    }
  );

  if (auth.user) return <>{props.component}</>;
  auth.am_i_logged_in().catch((e) => {
    navigate("/login?returnUrl=" + returnUrl);
  });
};
// return <Navigate to={props.redirect} state={{ from: location }} replace={false} />
