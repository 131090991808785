import React from "react";
import SideBarItem from "./SideBarItem";

function SideBar(props) {
  const { data } = props;
  return (
    <header className="nav">
      <nav className="nav__container__actions">
        {data?.map((stage) => (
          <SideBarItem data={stage} />
        ))}
      </nav>
    </header>
  );
}

export default SideBar;
