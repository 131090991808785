import { useState, useEffect } from "react";
import PostItem from "../../common/list_item/PostItem";
import { useBackend } from "../../hooks/Backend";
import { useAuthUserContext } from "../../auth/AuthContext";
import ScoreData from "./score_data.json";
import { useNavigate } from "react-router-dom";
import ScoreItem from "./ScoreItem";

function ScoreTab(props) {
  // const { data } = props;
  const navigate = useNavigate();
  const auth = useAuthUserContext();
  const api = useBackend();
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   if (ScoreData) setData(ScoreData.stages);
  // }, [ScoreData]);

  useEffect(() => {
    api.show_user_me_score().then(({ data }) => {
      setData(data.stages);
    });
  }, []);

  return (
    <div className="grid grid-cols-1 gap-4 mt-2 px-12 score-container ver-2">
      <div className="col-span-1 gap-4">
        {data != null && data.map((post) => <ScoreItem data={post} />)}
      </div>
    </div>
  );
}
export default ScoreTab;
