import { useState, useEffect } from "react";
import PostItem from "../../common/list_item/PostItem";
import { useBackend } from "../../hooks/Backend";
import { useAuthUserContext } from "../../auth/AuthContext";
import ScoreData from "../score_data.json";
import { useNavigate } from "react-router-dom";

function ScoreTab(props) {
  // const { data } = props;
  const navigate = useNavigate();
  const auth = useAuthUserContext();
  const api = useBackend();
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   if (ScoreData) setData(ScoreData.stages);
  // }, [ScoreData]);
  
  useEffect(() => {
    api.show_user_score(auth.user.id).then(({ data }) => {
      setData(data.stages);
    });
  }, []);

  return (
    // <div className="grid grid-cols-2 gap-4 mt-2 px-24 score-container new-ver">
    //   {data != null &&
    //     data.map((post) => (
    //       <div className="col-span-1 gap-4">
    //         <PostItem data={post} />
    //       </div>
    //     ))}
    // </div>
    <div className="grid grid-cols-2 gap-4 mt-2 px-24 post-container score-tab">
      {data != null &&
        data.map((post) => (
          <div className="col-span-1 gap-4">
            <PostItem
              data={post}
              minial
              onClick={() => navigate("/stage-prizes?id=" + post?.id)}
            />
          </div>
        ))}
    </div>
  );
}
export default ScoreTab;
