import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useImage } from "../../hooks/Image";
import Modal from "../../common/Modal";
import { useBackend } from "../../hooks/Backend";
import ToyItem from "./ToyItem";

function ScoreItem(props) {
  const navigate = useNavigate();
  const { data } = props;
  return (
    <>
      <div className="grid grid-cols-1 gap-4 mt-2">
        <div className="font-semibold text-[16px]">{data?.catchphrase}</div>
        <div className="col-span-1">
          <div className="toy-container">
            {data != null && data?.toys.map((toy) => <ToyItem data={toy} />)}
          </div>
        </div>
      </div>
    </>
  );
}
export default ScoreItem;
