import { React, memo, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { DeviceOrientationControls } from "@react-three/drei";
import "./GraffitiCanvas.css";
import { usePosition } from "./hooks/Position";
import HUD from "./hud/HUD";
import EventDetail from "./post/EventDetail";
import { useBackend } from "./hooks/Backend";
import { useLocation, useNavigate } from "react-router-dom";
import ToyDetail from "./post/ToyDetail";
import ToyDetailMock from "./post/ToyDetailMock";
import ToyDetalModal from "./post/ToyDetalModal";
import Modal from "./common/Modal";
import NotificationForUser from "./NotificationForUser";
import Wayawaya from "./wayawaya-festival/Wayawaya";
import IdolMihonIchi from "./event/idol_mihon_ichi";

function GraffitiCanvas(props) {
  const navigate = useNavigate();
  const [openPost, setOpenPost] = useState(false);
  const [event, setEvent] = useState(null);
  const [toy, setToy] = useState(null);
  const [listToy, setListToy] = useState([]);
  const [listOverLay, setListOverlay] = useState([]);
  const api = useBackend();
  const location = useLocation();
  const returnUrl = location.pathname + location.search;

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const [showToyModal, setShowToyModal] = useState(false);
  const [toyId, setToyId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [rare, setRare] = useState(false);
  
  const [openAuthPopup, setOpenAuthPopup] = useState(false);
  const [openPointPopup, setOpenPointPopup] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [event_id, setEventID] = useState("");

  const [permissionStatus, setPermissionStatus] = useState(false);
  const [videoStream, setVideoStream] = useState(false);

  // const emitter = props.emitter;

  useEffect(() => {
    const id = searchParams.get("id");
    setEventID(id);
    api.show_stage(id).then(({ data }) => {
      setEvent(data);
      setListToy(data.toys.filter((item) => item.display_type === "3d"));
      setListOverlay(
        data.toys.filter((item) => item.display_type === "overlay")
      );
    });

    //
    checkGyroPermission();
    checkVideoPermission();
  }, []);

  const checkGyroPermission = async () => {
    try {
      const orientationStatusWebkit = await navigator.permissions.query({name: "gyroscope"});
      if (orientationStatusWebkit.state === "granted") {
        setPermissionStatus(true);
      }
    } catch(er) {
      setPermissionStatus(false);
    }
  }

  const checkVideoPermission = async () => {
    if ("permissions" in navigator) {
      try {
        const videoPermissionStatus = await navigator.permissions.query({
          name: "camera",
        });
        if (videoPermissionStatus.state === "granted") {
          setVideoStream(true);
        }
      } catch (error) {
        setVideoStream(false);
      }
    }
  };
  const gacha = async (id) => {
    setLoading(true);
    return api
      .toy_action(id)
      .then((res) => {
        if (res != null && res.data) {
          setToy(res.data);
          setRare(["UR", "SSR"].filter(e => e === res.data.rank).length > 0)
          if (res.data.display_type === "3d") {
            setListToy((items) => [...items, res.data]);
          } else if (res.data.display_type === "overlay") {
            setListOverlay((items) => [...items, res.data]);
          } else {
            setShowToyModal(false);
            setOpenErrorPopup(true);
          }
        }
        setLoading(false);
        return res.data;
      })
      .catch(({ response }) => {
        setLoading(false);
        setShowToyModal(false);
        if (response?.status === 401 && response?.data?.code == "REACH_LIMIT") {
          //need login
          setOpenAuthPopup(true);
        } else if (
          response?.status === 402 &&
          response?.data?.code == "NOT_ENOUGH_POINT"
        ) {
          //not enough point
          setOpenPointPopup(true);
        } else {
          setOpenErrorPopup(true);
        }
        throw new Error();
      });
  };
  const handleOpenOverlay = (data) => {
    if (data?.toy_type === 'Ornament' ) return;
    setToyId(data?.id);
    setToy(data);
    setShowToyModal(true);
  };

  const [position, getCurrent] = usePosition();
  
  return (
    <>
      {!permissionStatus && <NotificationForUser />}
      { (
        <Canvas
          className="w-full h-full m-0 p-0 "
          gl={{
            antialias: false,
            powerPreference: "default",
            physicallyCorrectLights: true,
          }}
          sourceType="webcam"
          camera={{ fov: 23, near: 0.01, far: 10000, position: [0, 0, 0] }}
          onCreated={({ gl, camera }) => {
            gl.setSize(window.innerWidth, window.innerHeight);
          }}
        >
          <group>
            {listToy?.map((toy) => (
              <ToyDetail
                show={true}
                data={toy}
                handleOpenOverlay={handleOpenOverlay}
              />
            ))}
          </group>
          <IdolMihonIchi show={event_id === "c27ce089-5a2d-4c49-81b2-3aff7823e469"} />
          <DeviceOrientationControls />
        </Canvas>
      )}

      {/* <div className="toy-mock-container">
        {listOverLay?.map((toy) => (
          <ToyDetailMock data={toy} handleOpenOverlay={handleOpenOverlay} />
        ))}
      </div> */}

      <HUD
        positioning={position.active}
        display_object_count={5}
        movie_object_count={5}
        onPositionUpdate={() => getCurrent()}
        event={event}
      />

      {!openPost ? (
        <>
          <div className="hub-action">
            <div
              className="text-sm text-w-800 post-title"
              onClick={() => setOpenPost(true)}
            >
              {event?.instruction}
            </div>
          </div>
        </>
      ) : null}
      {openPost ? (
        <EventDetail data={event} onClose={() => setOpenPost(false)} />
      ) : null}
      {/* toy detail */}
      <ToyDetalModal
        toyId={toyId}
        toy={toy}
        gacha={gacha}
        loading={loading}
        open={showToyModal}
        setOpen={setShowToyModal}
        isRare={rare}
      />

      <Modal
        open={openAuthPopup}
        onClose={() => setOpenAuthPopup(false)}
        title="プレイするにはログインが必要です。"
        btnName="ログイン"
        onClick={() => navigate("/login?returnUrl=" + returnUrl)}
      />

      <Modal
        open={openPointPopup}
        onClose={() => setOpenPointPopup(false)}
        title="ポイントが不足しています。"
        btnName="チャージ"
        onClick={() => navigate("/point")}
      />

      <Modal
        open={openErrorPopup}
        onClose={() => setOpenErrorPopup(false)}
        title="何か問題があります"
      />
    </>
  );
}

export default memo(GraffitiCanvas);
