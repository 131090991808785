import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import PetaLogo from "../image/peta-logo.png";
import { useBackend } from "../hooks/Backend";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

//const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

function Checkout() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const [clientSecret, setClientSecret] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const api = useBackend();

  useEffect(() => {
    const amount = searchParams.get("amount");
    const item = searchParams.get("item");
    if ((item != null && amount) != null) {
      setSelectedProduct({
        name: item,
        price: amount,
      });
      api.create_payment_intent(amount, "JPY", "card").then(({ data }) => {
        setClientSecret(data.client_secret);
      });
    }
  }, []);

  const appearance = {
    theme: "minimal",
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="checkout-container">
      <div className="logo">
        <img src={PetaLogo} />
      </div>
      <div className="checkout-item">
        <h3 className="item-name">{selectedProduct?.name}</h3>
        <p className="item-price">¥ {selectedProduct?.price}</p>
      </div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm selectedProduct={selectedProduct} secret={clientSecret}/>
        </Elements>
      )}
    </div>
  );
}
export default Checkout;
export const checkoutURL = "/checkout";
