import { useState, useEffect } from "react";
import PostItem from "../common/list_item/PostItem";
import { useBackend } from "../hooks/Backend";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

function Event(props) {
  const { setLoading } = props;
  const [posts, setPosts] = useState([]);
  const api = useBackend();

  const [keyword, setKeyword] = useState("");

  function search(keyword) {
    api.show_list_stage(keyword).then(({ data }) => {
      setPosts(data.searched_stage);
      setLoading(false);
    });
  }
  useEffect(() => {
    search(null);
  }, []);

  // trigger when user click Enter Button
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      search(keyword);
    }
  }
  // trigger when user focusout
  function handleBlur() {
    search(keyword);
  }

  return (
    <>
      <div className="form-group search-input">
        <span class="input-group-addon r-0">
          <MagnifyingGlassIcon
            className="h-6 w-6"
            onClick={() => search(keyword)}
          />
        </span>
        <input
          id="keyword"
          className="form-control"
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        ></input>
      </div>

      <div className="grid grid-cols-1 gap-4 post-container">
        <div className="col-span-1 gap-4">
          {posts != null && posts.map((post) => <PostItem data={post} />)}
        </div>
      </div>
    </>
  );
}
export default Event;
