import React, { useState, useEffect } from "react";
// import PrizeItem from "../../common/list_item/PrizeItem";
import { PlusIcon } from "@heroicons/react/24/outline";
import StageSettingModal from "../StageSettingModal";
import RarityItem from "./RarityItem";

function Rarity(props) {
  const { data } = props;

  return (
    <>
      <div className="grid grid-cols-1 gap-4 score-container new-ver">
        <div className="font-semibold text-[10px]">{data?.catchphrase}</div>
        <div className="toy-container">
          {data?.map((toy) => (
            <RarityItem data={toy} />
          ))}
          <RarityItem empty />
        </div>
      </div>
    </>
  );
}

export default Rarity;
