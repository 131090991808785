import { useState, useEffect } from "react";
import { useImage } from "../../hooks/Image";
import Modal from "../../common/Modal";
import { useBackend } from "../../hooks/Backend";
import { useLocation, useNavigate } from "react-router-dom";
import PrizeItem from "./PrizeItem";
import Navigation from "../../hud/Navigation";
import StageInfo from "./StageInfo";

function StagePrizes(props) {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const [stage, setStage] = useState(null);
  const api = useBackend();
  const [lineup, setLineup] = useState([]);

  useEffect(() => {
    const id = searchParams.get("id");

    api.show_score_detail(id).then(({ data }) => {
      setStage(data);
    });

    // setStage({
    //   id: "c656871b-01ec-4553-b138-c4f9dab9187c",
    //   name: "レジェンドをあなたの手に。",
    //   image:
    //     "https://development.project-peta.com/images/toy/03b76d1c-489b-441e-b472-80cce6f14f6f.png",
    //   display_type: "3d",
    //   appear: null,
    //   rank: null,
    //   lineup: [
    //     {
    //       rank: "Ex",
    //       prizes: [
    //         {
    //           id: "f6e3e7ec-8205-497e-84d2-c3ec717d71e4",
    //           name: "明日花キララ：全裸",
    //           image:
    //             "https://development.project-peta.com/images/toy/1beac096-b18c-4598-bd60-6bcf53ddb6a6.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "297e7279-ff86-4b70-ab0f-71ab3c86670b",
    //           name: "紗倉まな：全裸",
    //           image:
    //             "https://development.project-peta.com/images/toy/2538b28d-73e6-4ec3-b0c2-db555df51460.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "5f4fe659-ab7e-4d06-a924-5a79b4982e70",
    //           name: "上原亜衣：ギリギリショット",
    //           image:
    //             "https://development.project-peta.com/images/toy/9afbeb2f-904b-4cdd-b4c5-afd6a05f3878.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "dcedc3a6-b286-4584-bfa3-3fc459327195",
    //           name: "深田えいみ：全裸",
    //           image:
    //             "https://development.project-peta.com/images/toy/f8e671a0-13c5-4128-83a4-a90bffce23eb.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //       ],
    //     },
    //     {
    //       rank: "N",
    //       prizes: [
    //         {
    //           id: "95420dfb-0f30-452b-9511-0a2bc9ef5564",
    //           name: "深田えいみ：コート",
    //           image:
    //             "https://development.project-peta.com/images/toy/d9d8efef-42b9-423f-a4cf-4dff3cd50da7.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "f17bcf6d-a429-4995-9ee0-9e90c5d1b6a2",
    //           name: "紗倉まな：通常",
    //           image:
    //             "https://development.project-peta.com/images/toy/3f74977b-edf5-4969-a1e0-918ff8bcbaff.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "615c23bc-c69c-418c-8fac-06c277bfb215",
    //           name: "明日花キララ：通常",
    //           image:
    //             "https://development.project-peta.com/images/toy/5e626a0e-6640-425b-86b6-4e8e2d11ccdf.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "ec7605ff-d9e1-4c4e-99f9-3127c5836b9a",
    //           name: "上原亜衣：通常",
    //           image:
    //             "https://development.project-peta.com/images/toy/32afdf8f-426d-43ca-ad8d-da816249b09b.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //       ],
    //     },
    //     {
    //       rank: "R",
    //       prizes: [
    //         {
    //           id: "ee4f69cc-e02b-4773-988f-a96c74c8e145",
    //           name: "深田えいみ：春",
    //           image:
    //             "https://development.project-peta.com/images/toy/cf32fcbf-71bf-4b69-9b87-14bc8d5fead8.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "db0b6236-8dd2-4951-ab24-74e43dd242da",
    //           name: "明日花キラ：ドレス",
    //           image:
    //             "https://development.project-peta.com/images/toy/eb815317-be43-40f4-a017-638f88ce5ef0.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "8d44e76e-01e9-4052-93fb-b3afa85724e0",
    //           name: "紗倉まな：ドレス",
    //           image:
    //             "https://development.project-peta.com/images/toy/f4e31fde-e02b-4529-b56b-c7df0cdafcfc.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "c3ed865f-702a-402e-9a15-c68a736d9468",
    //           name: "上原亜衣：肩出しドレス",
    //           image:
    //             "https://development.project-peta.com/images/toy/4c73e50a-a88a-4bfc-a879-c3b5000081b5.webp",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //       ],
    //     },
    //     {
    //       rank: "SR",
    //       prizes: [
    //         {
    //           id: "492f2b2b-e09f-4df8-87d2-9a5cfa312923",
    //           name: "明日花キララ：白",
    //           image:
    //             "https://development.project-peta.com/images/toy/606be35b-925c-43b8-84c3-c506d7b61bb0.webp",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "5fdfeb36-46b0-4946-8bde-f5ee8caa0d9e",
    //           name: "上原亜衣：オフ",
    //           image:
    //             "https://development.project-peta.com/images/toy/7d781113-4832-4c44-ae6b-d75d32d3e300.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "438ddd65-912c-4af7-adb6-cca8666e1a7d",
    //           name: "紗倉まな：私服",
    //           image:
    //             "https://development.project-peta.com/images/toy/15cee4e9-7eca-4ec7-be04-52c951d29919.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "09983e47-98bf-4351-8f69-953209c18bf5",
    //           name: "深田えいみ：ドレス",
    //           image:
    //             "https://development.project-peta.com/images/toy/a8d49d69-fd40-453a-a1bc-396e0bfcb162.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //       ],
    //     },
    //     {
    //       rank: "SSR",
    //       prizes: [
    //         {
    //           id: "f36f7b40-d82b-4dbc-b653-4419c1a71764",
    //           name: "上原亜衣：水着",
    //           image:
    //             "https://development.project-peta.com/images/toy/90bf4947-4e22-477a-beed-09ce2cc2349c.webp",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "65e5a9e4-3abd-4140-ade5-938113ade1ae",
    //           name: "深田えいみ：競泳水着",
    //           image:
    //             "https://development.project-peta.com/images/toy/d2d22dbf-0b31-453b-8cd3-360e3e01a4bb.avif",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "d1e1b709-e3dc-4dac-98b7-1d8ca486445b",
    //           name: "紗倉まな：スク水",
    //           image:
    //             "https://development.project-peta.com/images/toy/8180b872-5414-434a-8ea1-1aab3e1995ff.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "4e6c9534-4f23-4bd6-b74e-2f4ea2b79c8a",
    //           name: "明日花キララ：ナイトドレス",
    //           image:
    //             "https://development.project-peta.com/images/toy/31adbda7-1c9b-454b-bd97-1549c1f058c4.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //       ],
    //     },
    //     {
    //       rank: "UR",
    //       prizes: [
    //         {
    //           id: "4e317367-5ac1-4546-9cfe-3418916e4f66",
    //           name: "深田えいみ：網タイツ",
    //           image:
    //             "https://development.project-peta.com/images/toy/6680bc8f-a2db-4afd-8824-1c2f2201cdc2.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "3e7b62a1-8237-4703-9f9b-cf623159ae48",
    //           name: "明日花キララ：オリジナルデザイン水着",
    //           image:
    //             "https://development.project-peta.com/images/toy/8a5d0650-39ae-4806-9f37-1880ae4f30e5.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "61279923-3b44-44c8-9b1b-b61694005580",
    //           name: "上原亜衣：極小水着",
    //           image:
    //             "https://development.project-peta.com/images/toy/2bb1bb88-a82f-4589-8740-cc976e831156.webp",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "745906da-7b94-4359-a377-d167f3e6bfc6",
    //           name: "紗倉まな：極小水着",
    //           image:
    //             "https://development.project-peta.com/images/toy/0f32b627-3d32-414c-9b6b-8501d8b39b8f.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "61279923-3b44-44c8-9b1b-b61694005580",
    //           name: "上原亜衣：極小水着",
    //           image:
    //             "https://development.project-peta.com/images/toy/2bb1bb88-a82f-4589-8740-cc976e831156.webp",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //         {
    //           id: "745906da-7b94-4359-a377-d167f3e6bfc6",
    //           name: "紗倉まな：極小水着",
    //           image:
    //             "https://development.project-peta.com/images/toy/0f32b627-3d32-414c-9b6b-8501d8b39b8f.jpg",
    //           display_type: "overlay",
    //           appear: null,
    //           rank: null,
    //         },
    //       ],
    //     },
    //   ],
    // });
  }, []);

  useEffect(() => {
    if (stage != null && stage.lineup != null) {
      // const sortedList = sortedByLineuUp(stage.lineup);
      setLineup(stage.lineup);
    }
  }, [stage]);

  // const sortedByLineuUp = (list) => {
  //   const rankOrder = { UR: 1, SSR: 2, SR: 3, R: 4, N: 5, Ex: 6 };
  //   return list.sort((a, b) => rankOrder[a.rank] - rankOrder[b.rank]);
  // };

  return (
    <>
      <Navigation />

      {stage && <StageInfo data={stage} />}

      <div className="grid grid-cols-1 gap-4 mt-2 px-24 stage-prize">
        <div className="toy-container">
          {lineup?.map((item) => (
            <div className="col-span-1 line-up">
              {item.prizes.map((prize) => (
                <PrizeItem data={prize} rank={item.rank}/>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default StagePrizes;
export const stagePrizesURL = "/stage-prizes";
