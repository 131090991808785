import { useState } from "react";
import { useBackend } from "../hooks/Backend";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export default function PasswordResetSendMail() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  // const [validate_message, setValidateMessage] = useState([]);
  // const [sended, setSended] = useState(false);
  const api = useBackend();
  return (
    <>
      <div className="text-title flex justify-center mt-176">
        パスワード再設定メールを送信します
      </div>

      <div className="w-full p-12 flex flex-col">
        <div className="my-3 p-2 flex-1">
          <div className="form-group">
            <span class="input-group-addon">
              <EnvelopeIcon className="h-6 w-6" />
            </span>
            <input
              id="email"
              className="form-control"
              value={email}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setEmail(e.target.value);
                // email_validate()
              }}
            ></input>
          </div>
          {/* {validate_message
                .filter((e) => e.path.includes("email"))
                .map((e) => (
                  <label className="text-[#A72466] text-xs">{e.message}</label>
                ))} */}
        </div>
        <button
          disabled={email.length ? "" : "disabled"}
          className="btn btn-primary flex justify-center my-3"
          onClick={() =>
            api
              .send_mail_pass_forgot(email)
              .then((e) =>
                navigate("/user/setting?sendemailresetpassword=" + email)
              )
          }
        >
          送信
        </button>
      </div>
    </>
  );
}
export const passResetUrl = "/password-reset";
