import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function NavItem(props) {
  const location = useLocation();
  const { url, icon } = props;
  return (
    <div className={`nav-item ${location.pathname === url ? "active" : ""}`}>
      <Link to={url}>{icon}</Link>
    </div>
  );
}
export default NavItem;
