import { useState } from "react";
import Tabs from "../common/tab/Tabs";
import Panel from "../common/tab/Panel";
import Navigation from "../hud/Navigation";
import User from "./User";
import Event from "./Event";
import Ticket from "./Ticket";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Loader from "../common/loader/Loader";

function Search() {
  const [loading, setLoading] = useState(true);
  return (
    <>
      {loading && <Loader />}
      <Navigation />
      <div className="mt-70 flex-1 px-12 event-page">
        {/* <div className="form-group search-input">
          <span class="input-group-addon r-0">
            <MagnifyingGlassIcon className="h-6 w-6" />
          </span>
          <input id="keyword" className="form-control"></input>
        </div> */}
        {/* <Tabs className="grid grid-cols-3 gap-4 px-24 user-tab">
          <Panel title="ユーザー">
            <User />
          </Panel>
          <Panel title="イベント">
            <Event />
          </Panel>
          <Panel title="スポット">
            <Ticket />
          </Panel>
        </Tabs> */}
        <Event setLoading={setLoading} />
      </div>
    </>
  );
}
export default Search;
export const searchURL = "/search";
