import React, { useState } from "react";

function GachaPreview(props) {
  const { title, image, openPopup } = props;
  return (
    <>
      <div className="toy-content z-[300000]" onClick={() => openPopup(true)}>
        <div className="toy-body">
          <h1 className="title">{title}</h1>
        </div>

        <div className="toy-action">
          <div className="toy-action-item text-left">
            <a>
              他のガチャを
              <br />
              探す <br />
              <span>←</span>
            </a>
          </div>
          <div className="toy-action-item text-center m-auto">
            <a>
              <p>
                ガチャを 引く
                <br />
                <span>↓</span>
              </p>
            </a>
          </div>
          <div className="toy-action-item text-right">
            <a className="ml-auto">
              獲得一覧を
              <br />
              見る <br />
              <span>→</span>
            </a>
          </div>
        </div>
      </div>
      <div class="toy-layer"></div>
      <img src={image} alt="Logo" />
    </>
  );
}

export default GachaPreview;
