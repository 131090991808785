import { useState, useEffect } from "react";
import Navigation from "../hud/Navigation";
import PostItem from "../common/list_item/PostItem";
import { useBackend } from "../hooks/Backend";
import { useAuthUserContext } from "../auth/AuthContext";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import { VideoCameraIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Loader from "../common/loader/Loader";

function TimeLine() {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const api = useBackend();
  const auth = useAuthUserContext();
  // const user = auth?.user;
  const navigate = useNavigate();
  const [videoStream, setVideoStream] = useState(false);

  const [lockScreen, setLockScreen] = useState(false);

  const [hasGPSPermission, setHasGPSPermission] = useState(false);

  const [user, setUser] = useState(null);

  const requestVideoAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setVideoStream(true);
    } catch (error) {
      console.log(error);
      setVideoStream(false);
    }
  };

  const checkVideoPermission = async () => {
    if ("permissions" in navigator) {
      try {
        const videoPermissionStatus = await navigator.permissions.query({
          name: "camera",
        });
        if (videoPermissionStatus.state === "granted") {
          setVideoStream(true);
        }
      } catch (error) {
        setVideoStream(false);
      }
    }
  };

  // const checkGPSPermission = () => {
  //   if ("geolocation" in navigator) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then((permissionStatus) => {
  //         setHasGPSPermission(permissionStatus.state === "granted");
  //       });
  //   } else {
  //     console.log("Geolocation API is not supported in this browser");
  //   }
  // };

  // const checkScreenRotation = () => {
  //   // const isRotationLocked = !window.matchMedia("(orientation: portrait)")
  //   //   .matches;

  //   // if (isRotationLocked) {
  //   //   setLockScreen(true);
  //   // } else {
  //   //   setLockScreen(false);
  //   // }

  //   const mediaQuery = window.matchMedia("(orientation: portrait)");
  //   setLockScreen(mediaQuery.matches);
  // };

  useEffect(() => {
    const userId = user?.id;

    if (auth?.user != null) {
      setUser(auth.user);
    } else {
      api.setting_my_info().then(({ data }) => setUser(data));
    }

    async function getTimeline() {
      api.show_my_timeline().then(({ data }) => {
        setPosts(data.searched_stage);
        setLoading(false);
      });
    }

    getTimeline();

    // checkScreenRotation();
    checkVideoPermission();
    // checkGPSPermission();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Navigation />
      <div className="flex-1 px-12 mt-70 timeline-page">
        <div className="timeline-header">
          <h3 className="text-md text-w-800">タイムライン</h3>
          <div className="action">
            <div className="action-item">
              <VideoCameraIcon
                onClick={requestVideoAccess}
                className={`h-6 w-6 ${
                  videoStream ? "text-[#73A345]" : "text-[#dddddd]"
                }`}
              />
            </div>
            <div className="action-item">
              <DevicePhoneMobileIcon
                onClick={() => navigate("/sensor-setting")}
                className={`h-6 w-6 ${
                  user?.allow_use_gyro ? "text-[#73A345]" : "text-[#dddddd]"
                }`}
              />
            </div>
            <div className="action-item">
              <MapPinIcon
                onClick={() => navigate("/location-setting")}
                className={`h-6 w-6 ${
                  user?.allow_send_position_on_search
                    ? "text-[#73A345]"
                    : "text-[#dddddd]"
                }`}
              />
            </div>
            <div className="action-item">
              <div className="user-ava">
                <img src={user?.icon} alt="user ava" />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 post-container">
          <div className="col-span-1 gap-4">
            {posts != null && posts.map((post) => <PostItem data={post} />)}
          </div>
        </div>
      </div>
    </>
  );
}
export default TimeLine;
export const timelineURL = "/timeline";
