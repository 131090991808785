import React, { useState } from "react";
import ImageUpload from "../../common/upload/ImageUpload";
import GachaPreview from "./preview/GachaPreview";
import PrizePreview from "./preview/PrizePreview";
import { PlusIcon } from "@heroicons/react/24/outline";
import Modal from "../../common/Modal";
import TextInput from "../../common/form/TextInput";

function StageSettingModal(props) {
  const {
    title,
    setTitle,
    image,
    setImage,
    showStageModal,
    setShowStageModal,
    stageTitle,
    catchphrase,
  } = props;

  const handleUploadImage = (file, imagePreviewUrl) => {
    let formData = new FormData();
    formData.append("file", file);
    setImage(imagePreviewUrl);
    // console.log(imagePreviewUrl);
    // api
    //   .update_user_icon(formData)
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  };

  return (
    <div className="toy-detail-modal">
      <Modal
        className="stage-setting-modal"
        open={showStageModal}
        onClose={() => setShowStageModal(false)}
        content={
          <>
            <div className="grid grid-cols-3 gap-4 gacha-item-container">
              <div className="col-span-1 sub-modal px-4">
                <table className="w-100 mt-20">
                  <tr>
                    <th>名前</th>
                    <td>
                      <TextInput
                        name="title"
                        value={title}
                        setValue={setTitle}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>画像</th>
                    <th className="upload-td">
                      <div className="btn-user-upload">
                        <ImageUpload
                          icon={
                            <div className="btn-add">
                              <PlusIcon className="h-6 w-6" />
                            </div>
                          }
                          defaultImage={image}
                          name="icon"
                          handleUpload={handleUploadImage}
                        />
                      </div>
                    </th>
                  </tr>
                </table>
                <div className="modal-action">
                  <button
                    className="btn-default w-100 text-[#888888] text-w-600"
                    onClick={() => setShowStageModal(false)}
                  >
                    キャンセル
                  </button>
                  <button
                    className="btn-default w-100 text-w-600"
                    onClick={() => setShowStageModal(false)}
                  >
                    決定
                  </button>
                </div>
              </div>
              <div className="col-span-1 sub-modal">
                <PrizePreview
                  title={title}
                  image={image}
                  stageTitle={stageTitle}
                  catchphrase={catchphrase}
                />
              </div>

              <div className="col-span-1 sub-modal">
                <GachaPreview title={title} image={image} />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}

export default StageSettingModal;
