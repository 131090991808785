import { z, ZodError } from "zod";
import { useCheck } from "../hooks/check";

export const useValidate = () => {
  const email = z.string().email({ message: "メールアドレスの形式で入力してください。" });
  const password = z.string().regex(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,100}$/, { message: "パスワードは英数字８〜１００文字で入力してください。", });

  return {
    email,
    password,
  };
};
