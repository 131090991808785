import { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

function InfoItem(props) {
  const { onClick, icon, title, btnText, subTitle, btnIcon } = props;

  return (
    <div className="user-info-item">
      <div className="svg-icon">{icon}</div>
      <span className="text-sm title">{title}</span>
      <span className="text-sm">{subTitle}</span>
      {onClick &&
        <button className="d-flex text-sm text-w-600" onClick={onClick}>
          {btnText}
          {btnIcon ? btnIcon : <ChevronRightIcon className="h-6 w-6" />}
        </button>
      }

    </div>
  );
}
export default InfoItem;
