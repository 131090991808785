import {React,  memo }  from 'react'
// import Webcam from "react-webcam";
// import GraffitiCanvas from './components/GraffitiCanvas';
// import NotificationForUser from './components/NotificationForUser';
// import Decorator from './components/wayawaya-festival/Decorator';
import {EventEmitter} from 'fbemitter'
import PublicRoute  from './components/Routing/Route'
// import './App.css'
import './_app.scss'


function App() {
    const emitter = new EventEmitter();
    return ( <PublicRoute />);
}
export default memo(App);
