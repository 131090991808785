import { useState, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { useImage } from "../../hooks/Image";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import ToyDetailMock from "../../post/ToyDetailMock";
import ARPreview from "./preview/ARPreview";
import RarityItem from "./rarity/RarityItem";
import GachaPreview from "./preview/GachaPreview";
import StageSettingModal from "./StageSettingModal";
import Rarity from "./rarity/Rarity";
import TextInput from "../../common/form/TextInput";
import Slider from "react-input-slider";
import SliderInput from "../../common/form/SliderInput";

function GachaItem(props) {
  const navigate = useNavigate();
  const { data, stageTitle } = props;
  const [showStageModal, setShowStageModal] = useState(false);

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [catchphrase, setCatchphrase] = useState("");
  const [rateN, setRateN] = useState(50);
  const [rateR, setRateR] = useState(30);
  const [rateSR, setRateSR] = useState(15);
  const [rateSSR, setRateSSR] = useState(4);
  const [rateUR, setRateUR] = useState(1);

  const [height, setHeight] = useState(60);
  const [howFarRight, setHowFarRight] = useState(0);
  const [farness, setFarness] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    if (data) {
      setTitle(data.name);
      setCatchphrase(data.catchphrase);
      setImage(data.image);
    }
  }, [data]);

  const toys = [
    {
      id: "af3af06d-7cc1-45c4-b8fd-1eca4b4daccb",
      name: "手に入るのは石か、美男美女か。",
      image:
        "https://development.project-peta.com/images/toy/4ad50a20-81a3-4ecd-913f-7e2ef4404048.jpg",
      display_type: "3d",
      count: 1,
      rank: "SSR",
    },
    {
      id: "b0f2fe24-1a67-44c2-bc36-939bdb09f0eb",
      name: "手に入るのは石か、美男美女か。",
      image:
        "https://development.project-peta.com/images/toy/197d4d76-d4b2-40c0-8937-1af6a16939eb.jpg",
      display_type: "3d",
      count: 1,
      rank: "N",
    },
    {
      id: "b0f2fe24-1a67-44c2-bc36-939bdb09f0eb",
      name: "手に入るのは石か、美男美女か。",
      image:
        "https://development.project-peta.com/images/toy/48cac61c-d916-4a75-8dcf-4c558b4851a3.jpg",
      display_type: "3d",
      count: 1,
      rank: "SSR",
    },
    {
      id: "b0f2fe24-1a67-44c2-bc36-939bdb09f0eb",
      name: "手に入るのは石か、美男美女か。",
      image:
        "https://development.project-peta.com/images/toy/5508160e-f084-4378-99b0-14de511739a5.jpg",
      display_type: "3d",
      count: 1,
      rank: "SSR",
    },
    {
      id: "b0f2fe24-1a67-44c2-bc36-939bdb09f0eb",
      name: "手に入るのは石か、美男美女か。",
      image:
        "https://development.project-peta.com/images/toy/06a3a2bb-1224-4eb3-ab00-b4f8ce7b7403.jpg",
      display_type: "3d",
      count: 1,
      rank: "SSR",
    },
  ];

  return (
    <div id={data?.id}>
      <h2 className="text-w-800 text-lg">{title}</h2>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="col-span-1 gap-4">
          <div className="gacha-item">
            <div className="d-flex">
              <h2 className="text-md">排出率</h2>
              <p className="text-sm">
                ※排出率は合計が100になるように設定して下さい。
              </p>
            </div>
            <table className="w-100 mt-20">
              <tr>
                <td>N</td>
                <th>
                  <TextInput
                  type="number"
                    name="rateN"
                    value={rateN}
                    setValue={setRateN}
                    icon={<span>%</span>}
                    iconOnEnd
                  />
                </th>
              </tr>
              <tr>
                <td>R</td>
                <th>
                  <TextInput
                  type="number"
                    name="rateR"
                    value={rateR}
                    setValue={setRateR}
                    icon={<span>%</span>}
                    iconOnEnd
                  />
                </th>
              </tr>
              <tr>
                <td>SR</td>
                <th>
                  <TextInput
                  type="number"
                    name="rateSR"
                    value={rateSR}
                    setValue={setRateSR}
                    icon={<span>%</span>}
                    iconOnEnd
                  />
                </th>
              </tr>
              <tr>
                <td>SSR</td>
                <th>
                  <TextInput
                  type="number"
                    name="rateSSR"
                    value={rateSSR}
                    setValue={setRateSSR}
                    icon={<span>%</span>}
                    iconOnEnd
                  />
                </th>
              </tr>
              <tr>
                <td>UR</td>
                <th>
                  <TextInput
                  type="number"
                    name="rateUR"
                    value={rateUR}
                    setValue={setRateUR}
                    icon={<span>%</span>}
                    iconOnEnd
                  />
                </th>
              </tr>
            </table>
          </div>

          <div className="gacha-item mt-4">
            <h2>位置</h2>
            <table className="w-100 mt-20">
              <tr>
                <td>高さ</td>
                <th className="slider">
                  <SliderInput
                    name="height"
                    value={height}
                    setValue={setHeight}
                    xmax={300}
                    xmin={-300}
                  />
                </th>
              </tr>
              <tr>
                <td>左右</td>
                <th className="slider">
                  <SliderInput
                    name="howFarRight"
                    value={howFarRight}
                    setValue={setHowFarRight}
                    xmax={300}
                    xmin={-300}
                  />
                </th>
              </tr>
              <tr>
                <td>距離</td>
                <th className="slider">
                  <SliderInput
                    name="farness"
                    value={farness}
                    setValue={setFarness}
                    xmax={300}
                    xmin={0}
                  />
                </th>
              </tr>
            </table>
          </div>
        </div>
        <div className="col-span-1 gap-4">
          <div className="gacha-item">
            <h2>回転</h2>
            <table className="w-100">
              <tr>
                <td>上下</td>
                <th className="slider">
                  <SliderInput
                    name="latitude"
                    value={latitude}
                    setValue={setLatitude}
                    xmax={90}
                    xmin={-90}
                  />
                </th>
              </tr>
              <tr>
                <td>左右</td>
                <th className="slider">
                  <SliderInput
                    name="longitude"
                    value={longitude}
                    setValue={setLongitude}
                    xmax={180}
                    xmin={-180}
                  />
                </th>
              </tr>
            </table>
          </div>

          <div className="gacha-item mt-4 toy-detail-modal">
            <GachaPreview
              title={title}
              image={image}
              openPopup={setShowStageModal}
            />
            <StageSettingModal
              title={title}
              setTitle={setTitle}
              image={image}
              setImage={setImage}
              catchphrase={catchphrase}
              stageTitle={stageTitle}
              showStageModal={showStageModal}
              setShowStageModal={setShowStageModal}
            />
          </div>
        </div>
        <div className="col-span-1 gap-4">
          <div className="gacha-item ar-preview">
            <ARPreview
              title={title}
              image={image}
              catchphrase={catchphrase}
              height={height}
              howFarRight={howFarRight}
              farness={farness}
              latitude={latitude}
              longitude={longitude}
            />
          </div>
        </div>
      </div>
      <div className="">
        <h2 className="text-w-800 text-lg">プライズ設定</h2>
        <h2 className="text-w-800 text-lg mt-4" id={data?.id + "N"}>
          N
        </h2>
        <Rarity data={toys} />
        <h2 className="text-w-800 text-lg mt-4" id={data?.id + "R"}>
          R
        </h2>
        <Rarity data={toys} />
        <h2 className="text-w-800 text-lg mt-4" id={data?.id + "SR"}>
          SR
        </h2>
        <Rarity data={toys} />
        <h2 className="text-w-800 text-lg mt-4" id={data?.id + "SSR"}>
          SSR
        </h2>
        <Rarity data={toys} />
        <h2 className="text-w-800 text-lg mt-4" id={data?.id + "UR"}>
          UR
        </h2>
        <Rarity data={toys} />
      </div>
    </div>
  );
}
export default GachaItem;
