import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImage } from "../../hooks/Image";
import Modal from "../../common/Modal";
// import { useBackend } from "../../hooks/Backend";

function ToyItem(props) {
  const navigate = useNavigate();
  const { data, name, follow } = props;

  const { loading, error, image } = useImage(
    "rarity_frame/" + data?.rank + ".png"
  );

  const [showScoreModal, setShowScoreModal] = useState(false);

  return (
    <>
      <div
        className="post-item"
        onClick={() => navigate("/stage-prizes?id=" + data?.id)}
      >
        <img src={data?.image} alt="Logo" />

        <h2 className="text-sm text-w-600 break-words">{data.name}</h2>
        <h2 className="text-sm text-w-600 ">ガチャ</h2>
      </div>
    </>
  );
}
export default ToyItem;
