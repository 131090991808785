import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

function SideBarItem(props) {
  const { data } = props;

  return (
    <>
      <ul className="text-sm">
        <li className="">
          <Link
            className=""
            activeClass="active"
            smooth
            spy
            offset={-100}
            to={data?.id}
          >
            {data?.catchphrase}
          </Link>
        </li>
        <li>
          <ul>
            {data?.toys.map((toy) => (
              <li className="ml-8">
                <Link
                  className=""
                  activeClass="active"
                  smooth
                  offset={-100}
                  spy
                  to={toy.id}
                >
                  {toy.name}
                </Link>
                <ul className="ml-8">
                  <li>
                    <Link
                      className=""
                      activeClass="active"
                      smooth
                      offset={-100}
                      spy
                      to={toy.id + "N"}
                    >
                      N
                    </Link>
                    <ul className="ml-12">
                      <li>松本ももな</li>
                      <li>松本ももな</li>
                      <li>+</li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className=""
                      activeClass="active"
                      smooth
                      offset={-100}
                      spy
                      to={toy.id + "R"}
                    >
                      R
                    </Link>
                  </li>
                  <li>
                    <Link
                      className=""
                      activeClass="active"
                      smooth
                      offset={-100}
                      spy
                      to={toy.id + "SR"}
                    >
                      SR
                    </Link>
                  </li>
                  <li>
                    <Link
                      className=""
                      activeClass="active"
                      smooth
                      offset={-100}
                      spy
                      to={toy.id + "SSR"}
                    >
                      SSR
                    </Link>
                  </li>
                  <li>
                    <Link
                      className=""
                      activeClass="active"
                      smooth
                      offset={-100}
                      spy
                      to={toy.id + "UR"}
                    >
                      UR
                    </Link>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </>
  );
}

export default SideBarItem;
