import React from "react";

function TextareaInput(props) {
  const { label, name, value, setValue, icon, iconOnEnd, rows } = props;
  return (
    <>
      {label && <label>{label}</label>}

      <div className={`form-group textarea ${iconOnEnd ? "p-end" : ""}`}>
        {icon && <span class="input-group-addon">{icon}</span>}

        <textarea
          id={name}
          name={name}
          value={value}
          className="form-control"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          rows={rows ? rows : "4"}
        ></textarea>
      </div>
    </>
  );
}

export default TextareaInput;
