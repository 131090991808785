import { BrowserRouter, Route, Routes } from "react-router-dom";
import MRRootView, { mainViewURL } from "../MRRootView";
import Landing, { landingURL } from "../static_pages/Landing";
import Login, { loginURL } from "../auth/Login";
import Registration, { regisrationUrl } from "../auth/Registration";
import TermsOfService, { termURL } from "../static_pages/TermsOfService";
import PrivacyPolicy, { policyURL } from "../static_pages/PrivacyPolicy";
import { AuthUserProvider } from "../auth/AuthContext";
import { AuthGuard } from "../auth/AuthGuard";
import OAuthRegistrationHandler, {
  oauthRegisterUrl,
} from "../auth/OAuthRegisterHandler";
import OAuthLoginHandler, { loginCallbackURL } from "../auth/OAuthLoginHandler";
import RegistrationTokenVerifying, {
  registerVerifyUrl,
} from "../auth/RegistrationVerify";
import PasswordResetting, {
  passResetVerifyUrl,
} from "../auth/PasswordReseting";
import PasswordResetSendMail, {
  passResetUrl,
} from "../auth/PasswordResetSendMail";
import EmailResetSendMail, { mailResetUrl } from "../auth/EmailResetSendMail";
import User, { userURL } from "../user/Index";
import UserSetting, { userSettingURL } from "../user/UserSetting";
import LocationSetting, { locationSettingURL } from "../user/LocationSetting";
import SensorSetting, { sensorSettingURL } from "../user/SensorSetting";
import StageSetting, { stageSettingURL } from "../admin/stage/StageSetting";
import Search, { searchURL } from "../search/Index";
import TimeLine, { timelineURL } from "../post/TimeLine";
import TicketDetail, { ticketDetailURL } from "../post/TicketDetail";
// import Navigation from "../hud/Navigation";
import Checkout, { checkoutURL } from "../point/Checkout";
import Point, { pointURL } from "../point/Index";
import ChangeName, { changeNameUrl } from "../user/ChangeName";
import Law, { lawURL } from "../static_pages/Law";
import NewUser, { newUserURL } from "../user/NewUserScore";
import StagePrizes, { stagePrizesURL } from "../user/score2/StagePrizes";

export default function PublicRoute() {
  return (
    <AuthUserProvider>
      {/* <Navigation /> */}
      <BrowserRouter>
        <Routes>
          {/* public route  */}
          <Route exact path={landingURL} element={<Landing />} />

          <Route exact path={loginURL} element={<Login />} />


          <Route
            exact
            path={loginCallbackURL}
            element={<OAuthLoginHandler />}
          />

          <Route exact path={termURL} element={<TermsOfService />} />
          <Route exact path={policyURL} element={<PrivacyPolicy />} />
          <Route exact path={lawURL} element={<Law />} />

          <Route exact path={regisrationUrl} element={<Registration />} />
          <Route
            exact
            path={oauthRegisterUrl}
            element={<OAuthRegistrationHandler />}
          />
          <Route
            exact
            path={registerVerifyUrl}
            element={<RegistrationTokenVerifying />}
          />

          <Route
            exact
            path={passResetVerifyUrl}
            element={<PasswordResetting />}
          />
          <Route
            exact
            path={passResetUrl}
            element={<PasswordResetSendMail />}
          />
          <Route exact path={mailResetUrl} element={<EmailResetSendMail />} />

          {/* <Route exact path={sensorSettingURL} element={<SensorSetting />} />

          <Route exact path={locationSettingURL} element={<LocationSetting />} /> */}
          <Route exact path={timelineURL} element={<TimeLine />} />
          <Route exact path={mainViewURL} element={<MRRootView />} />
          {/* <Route exact path={newUserURL} element={<NewUser />} /> */}
          <Route exact path={stagePrizesURL} element={<StagePrizes />} />
          {/* public route  */}

          {/* private route  */}
          {/* <Route
            exact
            path={mainViewURL}
            element={<AuthGuard component={<MRRootView />} redirect="/login" />}
          /> */}
          <Route
            exact
            path={stagePrizesURL}
            element={<AuthGuard component={<StagePrizes />} redirect="/login" />}
          />
          <Route
            exact
            path={userURL}
            element={<AuthGuard component={<User />} redirect="/login" />}
          />
          <Route
            exact
            path={userSettingURL}
            element={
              <AuthGuard component={<UserSetting />} redirect="/login" />
            }
          />
          {/* <Route
            exact
            path={timelineURL}
            element={<AuthGuard component={<TimeLine />} redirect="/login" />}
          /> */}
          <Route
            exact
            path={searchURL}
            element={<AuthGuard component={<Search />} redirect="/login" />}
          />
          <Route
            exact
            path={ticketDetailURL}
            element={
              <AuthGuard component={<TicketDetail />} redirect="/login" />
            }
          />
          <Route
            exact
            path={changeNameUrl}
            element={<AuthGuard component={<ChangeName />} redirect="/login" />}
          />
          <Route
            exact
            path={sensorSettingURL}
            element={
              <AuthGuard component={<SensorSetting />} redirect="/login" />
            }
          />
          <Route
            exact
            path={locationSettingURL}
            element={
              <AuthGuard component={<LocationSetting />} redirect="/login" />
            }
          />
          <Route
            exact
            path={pointURL}
            element={<AuthGuard component={<Point />} redirect="/checkout" />}
          />
          <Route
            exact
            path={checkoutURL}
            element={
              <AuthGuard component={<Checkout />} redirect="/checkout" />
            }
          />
          <Route
            exact
            path={stageSettingURL}
            element={
              <AuthGuard component={<StageSetting />} redirect="/login" />
            }
          />
          {/* private route  */}
        </Routes>
      </BrowserRouter>
    </AuthUserProvider>
  );
}
