import { useState, useEffect } from "react";
import { useBackend } from "../hooks/Backend";
// import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export default function ChangeName() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  // const [userId, setUserId] = useState("");
  const api = useBackend();

  const handleSubmit = () => {
    const formData = user;
    formData.name = name;
    // formData.id = userId;
    api
      .update_user_info(formData)
      .then((response) => {
        navigate("/user/setting");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    api.setting_my_info().then(({ data }) => setUser(data));
  }, []);

  useEffect(() => {
    if (user != null && user.name != null) {
      setName(user.name);
    }
    // if (user != null && user.id != null) {
    //   setUserId(user.id);
    // }
  }, [user]);

  return (
    <>
      {/* <div className="text-title flex justify-center mt-140">
        ユーザー名
      </div> */}

      <div className="w-full p-12 flex flex-col">
        <div className="my-3 p-2 flex-1">
          <div className="form-group text-center">
            <label>ユーザー名</label>
            <input
              id="name"
              className="form-control"
              value={name}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>

          {/* <div className="form-group text-center">
            <label>ID</label>
            <input
              id="userId"
              className="form-control"
              value={userId}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setUserId(e.target.value)}
            ></input>
          </div> */}
        </div>
        <button
          className="btn btn-primary flex justify-center my-3"
          onClick={() => handleSubmit()}
        >
          送信
        </button>
      </div>
    </>
  );
}
export const changeNameUrl = "/change-name";
