import React, { Component } from "react";

class Tabs extends Component {
  state = {
    selected: this.props.selected || 0,
  };

  handleChange(index) {
    this.setState({ selected: index });
  }

  render() {
    return (
      <>
        <div className={this.props.className}>
          {this.props.children.map((elem, index) => {
            // let style = index === this.state.selected ? "selected" : "";
            return (
              <div
                key={index}
                className={`${
                  index === this.state.selected ? "active" : ""
                } user-tab-item`}
                onClick={() => this.handleChange(index)}
              >
                <div className="tab-label">{elem.props.title}</div>
              </div>
            );
          })}
        </div>
        <div className="tab">{this.props.children[this.state.selected]}</div>
      </>
    );
  }
}

export default Tabs;
