import { useState } from "react";
import { Link } from "react-router-dom";
import { useBackend } from "../hooks/Backend";
import { useValidate } from "../hooks/Validate";
import { EnvelopeIcon, KeyIcon } from "@heroicons/react/24/outline";

export function Registration() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [visiblePass, setVisiblePass] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [mail_wrong_input_msg, setMailWrongInputMsg] = useState([]);
  const [pass_wrong_input_msg, setPassWrongInputMsg] = useState([]);
  const [error_message, setErrorMessage] = useState([]);
  const [sended, setSended] = useState(false);
  // const redirect = useRedirect();
  const api = useBackend();
  const validate = useValidate();
  const wrong_email = () => {
    setMailWrongInputMsg([]);
    try {
      validate.email.parse(email);
    } catch (e) {
      setMailWrongInputMsg(JSON.parse(e.message).map((x) => x));
      return true;
    }
    return false;
  };
  const wrong_pass = () => {
    setPassWrongInputMsg([]);
    try {
      validate.password.parse(pass);
    } catch (e) {
      setPassWrongInputMsg(JSON.parse(e.message).map((x) => x));
      return true;
    }
    return false;
  };
  const register_flow = async () => {
    if (wrong_email() | wrong_pass()) return;
    await api.register(email, pass).catch((e) => {
      // if (e.response.data.detail === "REGISTER_USER_ALREADY_EXISTS")
      // api.send_mail_for_register(email)
      // .then((e) => redirect.to_mail_send())
      if (e.response.data.detail === "REGISTER_INVALID_PASSWORD") return;
      if (e.response.status === 422) return;
    });
    await api.send_mail_for_register(email).then(setSended(true));
  };
  const oauth = () => api.oauth_registration();
  return (
    <>
      {sended ? (
        <>
          <div className="text-title flex justify-center mt-100 text-lg">
            メールを送信しました！
          </div>
          <div className="w-full p-56 flex flex-col">
            <div className="my-3 flex-1">
              <div className="text-md font-black flex justify-center">
                information@project-peta.comからのメールを確認してください！
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-title flex justify-center mt-100">新規登録</div>
          {error_message
            .filter((e) => e.path.includes("email"))
            .map((e) => (
              <label className="text-[#A72466] text-xs">{e.message}</label>
            ))}
          <div className="w-full p-56 flex flex-col">
            <div className="my-3 flex-1">
              <div className="form-group">
                <span class="input-group-addon">
                  <EnvelopeIcon className="h-6 w-6" />
                </span>
                <input
                  id="email"
                  className="form-control"
                  value={email}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></input>
              </div>
              {mail_wrong_input_msg.map((e) => (
                <label className="text-[#A72466] text-xs">{e.message}</label>
              ))}
            </div>
            <div className="my-3 flex-1">
              <div className="form-group">
                <span class="input-group-addon">
                  <KeyIcon className="h-6 w-6" />
                </span>
                <input
                  id="email"
                  type="password"
                  value={pass}
                  className="form-control"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                ></input>
              </div>
              {pass_wrong_input_msg.map((e) => (
                <label className="text-[#A72466] text-xs">{e.message}</label>
              ))}
            </div>
            <div className="flex-1 flex">
              <div
                className="flex-none flex content-center justify-center mx-3 m-auto"
                onClick={() => setConfirmed(!confirmed)}
              >
                {confirmed ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
                    />
                  </svg>
                )}
              </div>
              <div className="flex-1 text-sm">
                <Link to="/privacy-policy" className="text-primary">
                  プライバシーポリシー
                </Link>
                および
                <Link to="/terms" className="text-primary">
                  利用規約
                </Link>
                に同意します
              </div>
            </div>

            <button
              disabled={
                confirmed && email.length && pass.length ? "" : "disabled"
              }
              className="btn btn-primary flex justify-center my-3"
              onClick={() => register_flow()}
            >
              登録
            </button>
            <button className="btn btn-secondary" onClick={() => oauth()}>
              Googleで登録
            </button>

            <div className="flex-1 flex flex-col my-3 p-2">
              <div className="flex-1 text-sm text-center">
                <Link to="/login" className="text-title">
                  ログイン
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "unset", float: "right" }}
                  >
                    <path
                      d="M1.25 1.5L8.75 9L1.25 16.5"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Registration;
export const regisrationUrl = "/register";
