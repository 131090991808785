import React from "react";
import TextInput from "./TextInput";
import Slider from "react-input-slider";

function SliderInput(props) {
  const { name, value, setValue, ...otherProps } = props;
  return (
    <>
      <TextInput type="number" name={name} value={value} setValue={setValue} />
      <Slider
        {...otherProps}
        className="slider-input"
        styles={{
          track: {
            backgroundColor: "black",
            height: "1px",
          },
          active: {
            backgroundColor: "black",
            height: "1px",
          },
          thumb: {
            backgroundColor: "black",
            width: 15,
            height: 15,
          },
        }}
        axis="x"
        x={value}
        onChange={({ x }) => setValue(x)}
      />
    </>
  );
}

export default SliderInput;
