import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImage } from "../../hooks/Image";
import Modal from "../../common/Modal";

function PrizeItem(props) {
  const navigate = useNavigate();
  const { data, name, follow } = props;
  const rank = props.rank ? props.rank : data?.rank;
  const { loading, error, image } = useImage("rarity_frame/" + rank + ".png");

  const [showScoreModal, setShowScoreModal] = useState(false);
  return (
    <>
      <div className="post-item" onClick={() => setShowScoreModal(true)}>
        <div class="image-frame">
          {!loading && image ? <img src={image} alt="user ava" /> : null}
        </div>
        <img src={data?.image} alt="Logo" />
      </div>

      <Modal
        className="toy-detail-modal p-fixed"
        open={showScoreModal}
        onClose={() => setShowScoreModal(false)}
        content={
          <>
            <div className="toy-content">
              <div className="toy-body">
                <h1 className="title text-left">{data?.name}</h1>
                <h1 className="sub-title text-left">
                  {data?.stage?.catchphrase}
                </h1>
                <div className="grid grid-cols-2">
                  <div className="col-span-1 text-left">
                    <h5 className="text-w-800">{data?.stage?.name}</h5>
                  </div>
                  <div className="col-span-1 text-right">
                    <h5>2022.10.31 〜 2022.11.1 東京都渋谷区</h5>
                  </div>
                </div>
              </div>

              <div className="toy-action"></div>
            </div>
            <div class="toy-layer"></div>
            <div class="image-frame">
              {!loading && image ? <img src={image} alt="user ava" /> : null}
            </div>
            <img src={data?.image} alt="Logo" />
          </>
        }
      />
    </>
  );
}
export default PrizeItem;
