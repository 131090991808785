import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthUserContext } from "./AuthContext";
import { useBackend } from "../hooks/Backend";
import { useRedirect } from "../hooks/Redirect";
import { useValidate } from "../hooks/Validate";
import { EnvelopeIcon, KeyIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

function Login() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const returnUrl = searchParams.get("returnUrl");

  const auth = useAuthUserContext();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate();

  const [cannot_login, setCannotLogin] = useState(false);
  const redirect = useRedirect();
  const api = useBackend();
  const validator = useValidate();
  const wrong_input = () => {
    try {
      validator.email.parse(email);
      validator.password.parse(pass);
    } catch (errors) {
      console.log(errors);
      setCannotLogin(true);
      return true;
    }
    return false;
  };

  const login = async () => {
    if (wrong_input()) return;
    await api
      .login(email, pass)
      .then(async (e) => {
        var user = await auth.am_i_logged_in();
        if (returnUrl != null) {
          navigate(returnUrl);
        } else if (user != null && user.data && user.data.name != null) {
          navigate("/timeline")
        } else {
          navigate("/user/setting");
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 400) setCannotLogin(true);
        if (e.response.status === 422) setCannotLogin(true);
        throw e;
      });
  };
  const oauth = () => api.oauth_registration();
  return (
    <>
      <div className="text-title flex justify-center mt-100">ログイン</div>
      <div className="w-full p-56 flex flex-col">
        {cannot_login ? (
          <label className="text-[#A72466] text-sm">
            ログインに失敗しました。メールアドレス・パスワードを確認してください！
          </label>
        ) : (
          ""
        )}
        <div className="my-3 flex-1">
          <div className="form-group">
            <span class="input-group-addon">
              <EnvelopeIcon className="h-6 w-6" />
            </span>
            <input
              id="email"
              className="form-control"
              value={email}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="my-3 flex-1">
          <div className="form-group">
            <span class="input-group-addon">
              <KeyIcon className="h-6 w-6" />
            </span>
            <input
              id="password"
              type="password"
              value={pass}
              className="form-control"
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setPass(e.target.value);
              }}
            ></input>
          </div>
        </div>
        <button
          className="btn btn-primary flex justify-center my-3"
          onClick={() => login()}
        >
          ログイン
        </button>
        <button className="btn btn-secondary" onClick={() => oauth()}>
          Googleでログイン
        </button>
        <div className="flex-1 flex flex-col my-3">
          <div className="flex-1 text-sm text-center">
            <Link to="/register" className="text-title">
              登録
              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "unset", float: "right" }}
              >
                <path
                  d="M1.25 1.5L8.75 9L1.25 16.5"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
export const loginURL = "/login";
