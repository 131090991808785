import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useBackend } from "../hooks/Backend";
import { useRedirect } from "../hooks/Redirect";

export default function RegistrationTokenVerifying(props) {
  const token = useParams();
  const redirect = useRedirect();
  const api = useBackend();
  const verify_flow = () => {
    api.verify_user(token)
      .then((e) => redirect.to_login())
      .catch((e) => {
        if (e.response.data.detail == "VERIFY_USER_ALREADY_VERIFIED") redirect.to_top();
        if (e.response.data.detail == "VERIFY_USER_BAD_TOKEN") redirect.to_top();
        if (e.response.status == 422) return; // validation error
        console.log(e.response.status, e.response.data.detail);
      });
  };
  verify_flow();
  return (
    <>
      <div className="text-md font-black flex justify-center mt-32">
        メールアドレスの検証中
      </div>
    </>
  );
}
export const registerVerifyUrl = "/register/verify/:token"