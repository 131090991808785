import { DocumentTextIcon } from "@heroicons/react/24/outline";

export default function Law() {
  return (
    <>
      <div className="text-title flex justify-center items-center mt-100 text-lg">
        <DocumentTextIcon className="h-6 w-6" />
        特商法
      </div>
      <div className="w-full p-56 flex flex-col doc">
        <strong>
          「特定商取引に関する法律」第11条（通信販売についての広告）に基づき以下に明示いたします。
        </strong>
        <strong>販売事業者</strong>株式会社キャリアブロックス
        <strong>運営責任者</strong>
        株式会社キャリアブロックス　代表取締役社長　佐々木 允耶
        <hr />
        <strong>所在地</strong>
        <p>
          〒104-0051　東京都港区芝1-9-2 ベルメゾン芝1001 お問い合わせ
          お問い合わせは以下のURLにございます「お問い合わせ」をご利用ください。
        </p>
        <hr />
        <strong>販売価格</strong>
        <p>
          本サービスで販売するコンテンツの購入手続きの際に画面に表示されます。
        </p>
        <hr />
        <strong>商品の引き渡し時期</strong>
        <p>
          デジタルコンテンツの場合は、ポイント購入後、指定のコンテンツでポイントを消費後にダウンロード可能となります（配信日・ダウンロード開始日が定められている商品については、ご注文後の当該デジタルコンテンツの配信日・ダウンロード開始日よりダウンロード可能となります）。
        </p>
        <hr />
        <strong>返品・キャンセル</strong>
        <p>不良品以外の返品・キャンセルはお受けいたしません</p>
        <hr />
        <strong>お支払い方法</strong>
        <p>
          コンテンツの購入に係る決済を代行する事業者が定めるお支払い方法によります。
        </p>
        <hr />
        <strong>お問合せ先</strong>
        <p>
          商品購入や会員サービスに関するお問い合わせは下記よりご連絡ください。
          <br />
          Peta会員向けインフォメーション
          <br />
          商品購入や会員サービスに関するお問い合わせ その他のお問い合わせ
        </p>
        <hr />
        <strong>株式会社キャリアブロックス</strong>
        <p>
          株式会社キャリアブロックス 〒105-0014　東京都港区芝1-9-2
          <br />
          ベルメゾン芝1001 URL：
          <a
            className="text-primary"
            target={"_blank"}
            href="https://careerblocks.jp"
          >
            https://careerblocks.jp
          </a>
        </p>
      </div>
    </>
  );
}
export const lawURL = "/law";
