import { useState } from "react";
import NavItem from "./NavItem";
import {
  HomeIcon,
  UserIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  AdjustmentsHorizontalIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";

function Navigation() {
  return (
    <div className="flex justify-between bottom-navigation">
      <NavItem url="/user" icon={<UserIcon className="h-6 w-6" />} />
      {/* <div className="nav-item">
        <button className="btn-post">
          <PlusIcon className="h-6 w-6 text-white" />
        </button>
      </div> */}
      <NavItem url="/point" icon={<ShoppingBagIcon className="h-6 w-6" />} />
      <NavItem url="/timeline" icon={<HomeIcon className="h-6 w-6" />} />
      <NavItem
        url="/search"
        icon={<MagnifyingGlassIcon className="h-6 w-6" />}
      />
      <NavItem
        url="/user/setting"
        icon={<AdjustmentsHorizontalIcon className="h-6 w-6" />}
      />
    </div>
  );
}
export default Navigation;
