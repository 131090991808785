import React, { createContext, useContext, useState } from "react";
import { useBackend } from "../hooks/Backend";

const AuthUserContext = createContext();

export const useAuthUserContext = () => {
  return useContext(AuthUserContext);
};

export const AuthUserProvider = (props) => {
  const [user, setUser] = useState(null);
  const api = useBackend();
  const logged_in = (newUser, callback) => {
    setUser(newUser.data);
    if (callback) callback();
  };

  const logged_out = async () => {
    await api.logout().then((e) => {
      setUser(null);
    });
  };

  const am_i_logged_in = async () => {
    var user = await api.setting_my_info();
    logged_in(user);
    return user;
  };

  const value = { user, setUser, logged_in, logged_out, am_i_logged_in };
  return (
    <AuthUserContext.Provider value={value}>
      {props.children}
    </AuthUserContext.Provider>
  );
};
