import { useState, useEffect } from "react";

function Modal(props) {
  const { title, text, open, onClose, content, className, onClick, btnName } =
    props;
  return (
    <>
      {open ? (
        <>
          <div className={`${className} alert-modal w-auto`}>
            <div className={`border-0 relative flex flex-col w-full outline-none focus:outline-none ${props.transparent ? "" : "rounded-lg shadow-lg bg-white"}`}>
              <div className="relative flex-auto text-center modal-body">
                {title ? <h3 className="text-sm text-w-600">{title}</h3> : null}

                {text ? <p className="text-sm">{text}</p> : null}

                {content}
                {btnName && (
                  <button
                    className="btn btn-primary flex justify-center my-3 w-100"
                    onClick={onClick}
                  >
                    {btnName}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={onClose}
          ></div>
        </>
      ) : null}
    </>
  );
}
export default Modal;
