import React, { useState } from "react";

function PrizePreview(props) {
  const { title, stageTitle, catchphrase, image, openPopup } = props;
  return (
    <>
      <div className="toy-content">
        <div className="toy-body">
          <h1 className="title text-left">{title}</h1>
          <h1 className="sub-title text-left">{catchphrase}</h1>
          <div className="grid grid-cols-2">
            <div className="col-span-1 text-left">
              <h5 className="text-w-800">{stageTitle}</h5>
            </div>
            <div className="col-span-1 text-right">
              <h5>2022.10.31 〜 2022.11.1 東京都渋谷区</h5>
            </div>
          </div>
        </div>

        <div className="toy-action"></div>
      </div>
      <div class="toy-layer"></div>
      <img src={image} alt="Logo" />
    </>
  );
}

export default PrizePreview;
