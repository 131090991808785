import { DocumentTextIcon } from "@heroicons/react/24/outline";

export default function PrivacyPolicy() {
  return (
    <>
      <div className="text-title flex justify-center items-center mt-100 text-lg">
        <DocumentTextIcon className="h-6 w-6" />
        プライバシーポリシー
      </div>
      <div className="w-full p-56 flex flex-col doc">
        <strong>1.個人情報の収集</strong>
        <p>
          個人情報とは、個人に関する情報であり、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの(他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。)を指します。
          <br />
          当社(株式会社キャリアブロックス 所在地:〒105-0014 東京都港区芝1-9-2
          ベルメゾン芝1001)は、個人情報を収集することがあります。当社は、個人情報の利用目的を公表します。
        </p>
        <hr />
        <strong>2.個人情報の利用目的について</strong>
        <p>
          当社は、お客様からご提供いただいた個人情報を以下の目的のために利用いたします。
          <ul>
            <li>
              （1）本サービス実施、提供のため
              <br />
              a.本サービスの拡充、改善、新規開発のための調査・分析のため
              <br />
              b.お問い合わせ、サポート対応のため
              <br />
              c.その他、お客様の同意を得た利用目的のため
            </li>
            <li>
              （2）本サービスの利用状況等に係る統計情報の作成、本サービスまたは当社の運営するウェブサイト等における公表のため。
            </li>
          </ul>
        </p>
        <hr />
        <strong>3．個人情報の提供について</strong>
        <p>
          当社は、以下に該当する場合、お客様の個人情報を第三者に提供することがあります。
          <ul>
            <li>
              （1）法令に基づく場合など個人情報保護法に定める場合や、裁判所・警察等の公的機関から法律に基づく要請を受けた場合。
            </li>
            <li>（2）ユーザーの事前の承認、同意を得た場合。</li>
            <li>
              （3）本サービスを提供する上で必要となる業務委託先に開示する場合。
            </li>
            <li>
              （4）当社の合併、分社化、事業譲渡により事業が継承されユーザー情報を引き継ぐ場合。
            </li>
          </ul>
        </p>
        <hr />
        <strong>4．個人情報の委託について</strong>
        <p>
          当社では、業務上必要な範囲で個人情報の取扱いを委託先へ委託することがあります。委託にあたり、十分な個人情報の保護水準を満たす委託先を選定、守秘義務を含む契約を締結いたします。なお、現時点においては、個人情報の取扱いを委託先に委託しておりませんが、今後変更のある場合には、本案内にて開示してまいります。
        </p>
        <hr />
        <strong>5．個人情報の提供に関する任意性について</strong>
        <p>
          個人情報を当社にご提供いただくか否かは、お客様ご自身の任意です。ただし、当社が提供をお願いする個人情報をご提供いただけない場合や、ご提供いただいた個人情報に不備があった場合には、個人情報の取得目的通りのサービスがご提供できない場合があります。
        </p>
        <hr />
        <strong>6．個人情報の開示等について</strong>
        <p>
          当社は、お客様からご提供いただいた個人情報の利用目的の通知、開示、訂正、追加、削除、利用停止または第三者提供停止（以下、「開示等」という。）の請求に対応いたします。ご希望があるお客様は、本案内下部記載のPetaユーザーサポート担当まで、ご連絡ください。
        </p>
        <hr />
        <strong>7．アクセス情報の収集について</strong>
        <p>
          本サービスにアクセスされた際に、インターネットに接続するときに使用されるIPアドレス、コンピュータに関する情報と接続情報（ブラウザの種類とバージョン、オペレーティングシステム、プラットフォームなど）、本サービスへのアクセス、ならびに当社サイトからのアクセスに関する、完全なURLの日付と時間を含む記録、表示した当社サイト内のページ情報を収集いたします。これらの情報は統計的に処理され、本サービスの各ページに表示する情報の制御や当社サイトの利便性の向上に利用いたします。
        </p>
        <hr />
        <strong>8．プライバシーポリシーの更新</strong>
        <p>
          当社は、プライバシーポリシーを変更する場合があります。
          プライバシーポリシーに重要な変更がある場合には、サイト上で告知します。
          本ページを都度ご確認の上、当社のプライバシーポリシーをご理解いただくようお願いします。
        </p>
        <hr />
        <strong>9．お客様へのお願い</strong>
        <p>
          当社運営サイトでは、お客様の登録情報をID・パスワードで保護している場合があります。ID・パスワードの管理は、お客様ご自身が責任を持って行ってください。
          当社運営サイトを安全にご利用いただけるよう、当社運営サイト上に、他の方やお客様ご自身の個人情報は掲載されないようお願いいたします。
        </p>
        <hr />
        <strong>10．クッキーについて</strong>
        <p>
          本サービスでは、クッキーを使用する場合があります。これは本サービスの改善や利用状況の解析のため、ユーザーによるWEBサイト、アプリ、広告の閲覧状況を確認するもので、統計的なマーケティング情報として利用いたします。クッキー内に個人情報が含まれることはございません。また、個人情報の取得およびユーザー情報との関連付けを行うものではありません
        </p>
        <hr />
        <strong>11．個人情報の開示等およびお問い合わせについて</strong>
        <p>
          個人情報の取り扱いに関するお問い合わせ、苦情などは以下のお問い合わせ先までお願いいたします。
        </p>
        <hr />
        <strong>株式会社キャリアブロックス</strong>
        <p>〒105-0014 東京都港区芝1-9-2 ベルメゾン芝1001</p>
        <strong>メール: </strong>info@careerblocks.jp
        <strong>個人情報保護管理者: </strong>Petaユーザーサポート担当
        <strong>制定日: </strong>令和5年4月1日
      </div>
    </>
  );
}
export const policyURL = "/privacy-policy";
