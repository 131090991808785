import React from "react";

function TextInput(props) {
  const { label, name, value, type, setValue, icon, iconOnEnd, ...otherProps } =
    props;
  return (
    <>
      {label && <label>{label}</label>}

      <div className={`form-group ${iconOnEnd ? "p-end" : ""}`}>
        {icon && <span class="input-group-addon">{icon}</span>}

        <input
          {...otherProps}
          id={name}
          name={name}
          value={value}
          className="form-control"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          type={type ? type : "text"}
        ></input>
      </div>
    </>
  );
}

export default TextInput;
